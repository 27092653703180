/* eslint-disable jsx-a11y/iframe-has-title */
import { PriceExplorerElectricity } from "./components/PriceExplorerElectricity";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { PriceExplorerGas } from "./components/PriceExplorerGas";
import { HeaderMiddle } from "./components/header";
import { Click2Go } from "./components/click-to-go";
import { Contact } from "./components/contact";
import { NotFound } from "./components/404";
import { About as EnergyAbout } from "./components/energy-about";
import { Home } from "./components/home";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { FooterSocial } from "./components/footer";
import { BiHome, BiBriefcase } from "react-icons/bi";
import { TbPlug } from "react-icons/tb";
import { GoFlame } from "react-icons/go";
import Logo from "./logo.svg";
import { Center, createStyles } from "@mantine/core";
import { About } from "./components/about";
import { Legal } from "./components/legal";
import { MediaQuery } from "@mantine/core";

import "react-toastify/dist/ReactToastify.css";
import { SuperMarket } from "./supermarket";

const useStyles = createStyles((theme) => ({
    banner: {
        maxWidth: "80%",
        transform: "scale(3)",
        marginBottom: "1.5rem",
    },
    center: {
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        alignItems: "center",

        [theme.fn.smallerThan("sm")]: {
            justifyContent: "flex-start",
            flexDirection: "column",
        },
    },
    ad: {
        zIndex: 6,
    },
    bannerLeft: {
        position: "absolute",
        left: 0,
    },
    bannerRight: {
        position: "absolute",
        right: 0,
    },
    bannerTop: {
        position: "absolute",
        top: "100%",
        left: "calc(50% - calc(728px / 2))",
    },
    bannerBottom: {
        position: "absolute",
        left: "calc(50% - calc(728px / 2))",
    },
}));

const getDate = (day: number, month: number, year: number) => new Date(year, month, day);

export const adData = {
    vertical: {
        bannerIds: [
            { bannerId: "12158-1361", bannerSizing: "vertical" },
            { bannerId: "12158-19", bannerSizing: "vertical" },
            { bannerId: "12158-2214", bannerSizing: "vertical", expiresAt: getDate(2, 11, 2022) },
            { bannerId: "13533-7", bannerSizing: "vertical" },
            { bannerId: "12587-12", bannerSizing: "vertical" },
            { bannerId: "12587-13", bannerSizing: "vertical" },
            { bannerId: "12587-68", bannerSizing: "vertical" },
            { bannerId: "12587-69", bannerSizing: "vertical" },
            { bannerId: "12587-70", bannerSizing: "vertical" },
            { bannerId: "12587-71", bannerSizing: "vertical" },
            { bannerId: "12587-72", bannerSizing: "vertical" },
            { bannerId: "12587-73", bannerSizing: "vertical" },
            { bannerId: "12023-9", bannerSizing: "vertical" },
            { bannerId: "12624-12", bannerSizing: "vertical" },
            { bannerId: "12624-13", bannerSizing: "vertical" },
            { bannerId: "12624-14", bannerSizing: "vertical" },
            { bannerId: "12624-15", bannerSizing: "vertical" },
            { bannerId: "11445-1023", bannerSizing: "vertical", expiresAt: getDate(1, 5, 2023) },
            { bannerId: "11815-55", bannerSizing: "vertical" },
            { bannerId: "11815-62", bannerSizing: "vertical" },
            { bannerId: "11815-79", bannerSizing: "vertical" },
            { bannerId: "11815-90", bannerSizing: "vertical" },
            { bannerId: "12158-19", bannerSizing: "vertical" },
            { bannerId: "12158-2217", bannerSizing: "vertical", expiresAt: getDate(6, 11, 2022) },
            { bannerId: "12158-2221", bannerSizing: "vertical", expiresAt: getDate(9, 11, 2022) },
            { bannerId: "12158-942", bannerSizing: "vertical" },
        ],
    },
    horizontal: {
        bannerIds: [
            { bannerId: "11809-1802", bannerSizing: "horizontal" },
            { bannerId: "52-12", bannerSizing: "horizontal" },
            { bannerId: "52-6", bannerSizing: "horizontal" },
            { bannerId: "52-9", bannerSizing: "horizontal" },
            { bannerId: "11834-30", bannerSizing: "horizontal" },
            { bannerId: "11834-38", bannerSizing: "horizontal" },
            { bannerId: "11834-39", bannerSizing: "horizontal" },
            { bannerId: "11834-46", bannerSizing: "horizontal" },
            { bannerId: "11834-31", bannerSizing: "horizontal" },
            { bannerId: "13533-9", bannerSizing: "horizontal" },
            { bannerId: "12023-14", bannerSizing: "horizontal" },
            { bannerId: "12587-21", bannerSizing: "horizontal" },
            { bannerId: "12587-22", bannerSizing: "horizontal" },
            { bannerId: "12587-29", bannerSizing: "horizontal" },
            { bannerId: "12587-80", bannerSizing: "horizontal" },
            { bannerId: "12587-81", bannerSizing: "horizontal" },
            { bannerId: "12587-82", bannerSizing: "horizontal" },
            { bannerId: "12587-83", bannerSizing: "horizontal" },
            { bannerId: "12587-84", bannerSizing: "horizontal" },
            { bannerId: "12587-85", bannerSizing: "horizontal" },
            { bannerId: "12587-86", bannerSizing: "horizontal" },
            { bannerId: "12587-87", bannerSizing: "horizontal" },
            { bannerId: "12587-88", bannerSizing: "horizontal" },
            { bannerId: "12624-16", bannerSizing: "horizontal" },
            { bannerId: "12624-17", bannerSizing: "horizontal" },
            { bannerId: "12624-18", bannerSizing: "horizontal" },
            { bannerId: "12624-19", bannerSizing: "horizontal" },
            { bannerId: "11641-725", bannerSizing: "horizontal" },
            { bannerId: "11445-1026", bannerSizing: "horizontal", expiresAt: getDate(1, 5, 2023) },
            { bannerId: "11445-1038", bannerSizing: "horizontal", expiresAt: getDate(1, 4, 2023) },
            { bannerId: "11815-10", bannerSizing: "horizontal" },
            { bannerId: "11815-32", bannerSizing: "horizontal" },
            { bannerId: "11815-47", bannerSizing: "horizontal" },
            { bannerId: "11815-49", bannerSizing: "horizontal" },
            { bannerId: "11815-66", bannerSizing: "horizontal" },
            { bannerId: "12158-1362", bannerSizing: "horizontal" },
            { bannerId: "12158-22", bannerSizing: "horizontal" },
            { bannerId: "12158-2216", bannerSizing: "horizontal", expiresAt: getDate(6, 11, 2022) },
            { bannerId: "12158-2222", bannerSizing: "horizontal", expiresAt: getDate(9, 11, 2022) },
        ],
    },
    mobileHorizontal: {
        bannerIds: [
            { bannerId: "52-14", bannerSizing: "horizontal", expiresAt: undefined },
            { bannerId: "52-5", bannerSizing: "horizontal" },
            { bannerId: "11834-29", bannerSizing: "horizontal" },
            { bannerId: "11834-32", bannerSizing: "horizontal" },
            { bannerId: "11834-37", bannerSizing: "horizontal" },
            { bannerId: "11834-40", bannerSizing: "horizontal" },
            { bannerId: "11834-45", bannerSizing: "horizontal" },
            { bannerId: "12587-19", bannerSizing: "horizontal" },
            { bannerId: "12587-20", bannerSizing: "horizontal" },
            { bannerId: "12587-28", bannerSizing: "horizontal" },
            { bannerId: "12023-13", bannerSizing: "horizontal" },
            { bannerId: "11641-723", bannerSizing: "horizontal" },
            { bannerId: "11815-31", bannerSizing: "horizontal" },
            { bannerId: "11815-44", bannerSizing: "horizontal" },
            { bannerId: "11815-45", bannerSizing: "horizontal" },
            { bannerId: "11815-48", bannerSizing: "horizontal" },
            { bannerId: "11815-65", bannerSizing: "horizontal" },
            { bannerId: "11815-71", bannerSizing: "horizontal" },
            { bannerId: "11815-82", bannerSizing: "horizontal" },
            { bannerId: "11815-9", bannerSizing: "horizontal" },
        ],
    },
};

export const rotatingBanners = {
    left: {
        baseLink: "//go.linkwi.se/delivery/ri.php?r=19649&a=55056&target=_blank&",
        position: "vertical",
        width: "300",
        height: "600",
        id: "lkws_63616e9d81b94",
    },
    right: {
        baseLink: "//go.linkwi.se/delivery/ri.php?r=19652&a=55056&target=_blank&",
        position: "vertical",
        width: "300",
        height: "600",
        id: "lkws_63617a7f4365e",
    },
    center: {
        baseLink: "//go.linkwi.se/delivery/ri.php?r=19665&a=55056&target=_blank&",
        id: "lkws_6363af9cdd5c1",
        height: "90",
        width: "728",
    },
    mobileCenter: {
        baseLink: "//go.linkwi.se/delivery/ih.php?cn={ID}&an=CD26116&target=_blank",
        height: "90",
        width: "468",
    },
};

function App() {
    const { classes, cx } = useStyles();

    const pcScreenQuery = `(max-width: ${Math.max(...[rotatingBanners.left, rotatingBanners.right].map((rb) => parseInt(rb.width))) * 5.1}px)`;

    return (
        <>
            <Helmet>
                <title>Psonistiri</title>
            </Helmet>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
            <div style={{ minHeight: "89vh", marginBottom: "0.7vh" }}>
                <BrowserRouter>
                    <div>
                        <div style={{ zIndex: 999, position: "relative" }}>
                            <HeaderMiddle />
                            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                                {
                                    [rotatingBanners.center].map((b) => {
                                        let bannerIds = adData.horizontal.bannerIds;
                                        bannerIds.sort((a) => Math.random() - 0.5);
                                        bannerIds = bannerIds.filter((banner) => banner.expiresAt === undefined || Date.now() < banner.expiresAt.getTime());

                                        const bannerData = new URLSearchParams();
                                        for (let i = 0; i < bannerIds.length; i++) {
                                            bannerData.append(`subid${i + 1}`, bannerIds[i].bannerId);
                                        }

                                        return <iframe className={cx(classes.bannerTop, classes.ad)} style={{ width: `${b!!.width}px`, height: `${b!!.height}px` }} id={b!!.id} name={b!!.id} src={b!!.baseLink + bannerData.toString()} scrolling="no" frameBorder="0"></iframe>;
                                    })[0]
                                }
                            </MediaQuery>
                        </div>
                        <div style={{ display: "grid", gridAutoRows: "auto auto auto" }}>
                            <MediaQuery query={pcScreenQuery} styles={{ display: "none" }}>
                                {
                                    [rotatingBanners.left].map((b) => {
                                        let bannerIds = adData.vertical.bannerIds;
                                        bannerIds.sort((a) => Math.random() - 0.5);
                                        bannerIds = bannerIds.filter((banner) => banner.expiresAt === undefined || Date.now() < (banner.expiresAt as Date).getTime());

                                        const bannerData = new URLSearchParams();
                                        for (let i = 0; i < bannerIds.length; i++) {
                                            bannerData.append(`subid${i + 1}`, bannerIds[i].bannerId);
                                        }

                                        return <iframe className={cx(classes.bannerLeft, classes.ad)} style={{ width: `${b!!.width}px`, height: `${b!!.height}px` }} id={b!!.id} name={b!!.id} src={b!!.baseLink + bannerData.toString()} scrolling="no" frameBorder="0"></iframe>;
                                    })[0]
                                }
                            </MediaQuery>

                            <div style={{ zIndex: 1, position: "relative", marginTop: "3%" }}>
                                <Center style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <Link to="/">
                                        <img src={Logo} alt="Ψωνιστήρι" width="150" className={classes.banner} />
                                    </Link>

                                    <h2 style={{ fontWeight: "normal" }}>
                                        Αυστηρά, για <span style={{ color: "orange" }}>ψαγμένους</span> καταναλωτές...
                                    </h2>
                                </Center>
                                <div>
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/energy/about" element={<EnergyAbout />} />
                                        <Route path="/about" element={<About />} />
                                        <Route path="/contact" element={<Contact />} />
                                        <Route path="/legal" element={<Legal />} />
                                        <Route
                                            path="/energy"
                                            element={
                                                <Click2Go
                                                    choices={[
                                                        { title: "Ηλεκτρικό ρεύμα", path: "electricity", icon: <TbPlug /> },
                                                        { title: "Φυσικό Αέριο", path: "gas", icon: <GoFlame /> },
                                                    ]}
                                                />
                                            }
                                        />

                                        <Route path="/energy/electricity/:who" element={<PriceExplorerElectricity />} />
                                        <Route path="/energy/gas" element={<PriceExplorerGas />} />
                                        {/* <Route path="/supermarket" element={<SuperMarket />} /> */}
                                        <Route
                                            path="/energy/electricity"
                                            element={
                                                <Click2Go
                                                    choices={[
                                                        { title: "Οικιακοί Πελάτες", path: "home", icon: <BiHome /> },
                                                        { title: "Επιχειρήσεις", path: "business", icon: <BiBriefcase /> },
                                                    ]}
                                                />
                                            }
                                        />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </div>
                            </div>

                            <MediaQuery query={pcScreenQuery} styles={{ display: "none" }}>
                                {
                                    [rotatingBanners.right].map((b) => {
                                        let bannerIds = adData.vertical.bannerIds;
                                        bannerIds.sort((a) => Math.random() - 0.5);
                                        bannerIds = bannerIds.filter((banner) => banner.expiresAt === undefined || Date.now() < (banner.expiresAt as Date).getTime());

                                        const bannerData = new URLSearchParams();
                                        for (let i = 0; i < bannerIds.length; i++) {
                                            bannerData.append(`subid${i + 1}`, bannerIds[i].bannerId);
                                        }

                                        return <iframe className={cx(classes.bannerRight, classes.ad)} style={{ width: `${b!!.width}px`, height: `${b!!.height}px` }} id={b!!.id} name={b!!.id} src={b!!.baseLink + bannerData.toString()} scrolling="no" frameBorder="0"></iframe>;
                                    })[0]
                                }
                            </MediaQuery>
                        </div>
                    </div>
                </BrowserRouter>
            </div>
            <FooterSocial />
        </>
    );
}

export default App;
