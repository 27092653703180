import { Center } from "@mantine/core";
import { createStyles } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
    about: {
        width: "50%",
        textAlign: "center",
        [theme.fn.smallerThan("sm")]: {
            "&": {
                width: "90%",
            },
        },
    },
}));

export const About = () => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    return (
        <Center>
            <span className={classes.about}>
                Το psonistiri.gr δημιουργήθηκε με σκοπό να βοηθήσει τον καταναλωτή να πραγματοποιεί έξυπνες και συμφέρουσες αγορές σε μια εποχή που η εξοικονόμηση χρημάτων είναι για το κάθε νοικοκυριό μέγιστης σημασίας.
                <br />
                <br />
                Η ομάδα μας, κατόπιν ερευνών, πραγματοποιεί και φέρνει σε εσάς ένα εύχρηστο εργαλείο ως πολύτιμο βοηθό για τις ανάγκες του σπιτιού σας ή της επιχείρησής σας.
                <br />
                <br />
                Όραμά μας είναι η ανάπτυξη σύγχρονων και καινοτόμων εφαρμογών για καταναλωτές και εμπόρους που θα λειτουργήσουν ως αρωγοί διάφανων, λογικών και τίμιων συναλλαγών.
                <br />
                <br />
                Θέλετε να δείτε κάτι επιπλέον από εμάς; Μπορείτε να μας στείλετε τις προτάσεις σας στο <a href="mailto:info@psonistiri.gr">info@psonistiri.gr</a>.<br />
                Εναλλακτικά στείλτε μας το μήνυμά σας πατώντας {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    href="/contact"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate("/contact");
                    }}
                >
                    εδώ
                </a>{" "}
                ή βρείτε μας στα social media.
            </span>
        </Center>
    );
};
