import { Click2Go } from "./click-to-go";
import { BiCartAlt } from "react-icons/bi";
import { BsLightningCharge } from "react-icons/bs";

export function Home() {
    return (
        <div>
            <Click2Go
                choices={[
                    { title: "Ενέργεια", path: "/energy", icon: <BsLightningCharge /> },
                    { title: "SuperMarket", path: "/supermarket", icon: <BiCartAlt />, disabled: true },
                ]}
            />
        </div>
    );
}
