import { Center, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    legal: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        width: "40%",
        [theme.fn.smallerThan("sm")]: {
            "&": {
                width: "90%",
            },
        },
    },
}));

export const Legal = () => {
    const { classes } = useStyles();
    return (
        <Center>
            <div className={classes.legal}>
                <h3>Όροι Χρήσης {"&"} Πολιτική Ιδιωτικού Απορρήτου ιστοτόπου psonistiri.gr</h3>
                <p>
                    Η ιστοσελίδα psonistiri.gr παρέχει τις υπηρεσίες της στους επισκέπτες / πελάτες της σύμφωνα με τους ακόλουθους Όρους Χρήσης. Η ανάγνωση του παρόντος κειμένου απαιτείται πριν από τη χρήση των υπηρεσιών της εταιρίας. Η psonistiri.gr δηλώνει στον επισκέπτη ότι λαμβάνει κάθε αναμενόμενο μέτρο, έτσι ώστε τα στοιχεία και οι πληροφορίες που εμπεριέχονται στην ιστοσελίδα της να είναι
                    πλήρη, ακριβή και αξιόπιστα.
                </p>

                <h3>- Πνευματική ιδιοκτησία</h3>
                <p>
                    Η ιστοσελίδα www.psonistiri.gr και το περιεχόμενο της (συμπεριλαμβανομένων ιδεών, κειμένων, γραφικών, εικόνων, φωτογραφιών, σχεδίων, λογισμικού, συνδέσμων, newsletter κλπ) αποτελούν πνευματική ιδιοκτησία της εταιρείας Psonistiri.gr και προστατεύονται από τους ελληνικούς, κοινοτικούς και διεθνείς νόμους περί εμπορικών σημάτων και βιομηχανικής και πνευματικής ιδιοκτησίας καθώς
                    επίσης η παρουσίαση των παραπάνω στην ιστοσελίδα www.psonistiri.gr σε καμία περίπτωση δεν θα πρέπει να εκλαμβάνονται ως μεταβίβαση ή εκχώρηση αδείας ή δικαιώματος χρήσης τους. Η αντιγραφή, αναλογική / ψηφιακή εγγραφή και μηχανική αναπαραγωγή, διανομή, μεταφορά, downloading, μεταποίηση, μεταπώληση, δημιουργία παράγωγης εργασίας ή παραπλάνηση του κοινού σχετικά με τον πραγματικό
                    παροχέα του περιεχομένου, τυχόν αναπαραγωγή, επανέκδοση, μεταφόρτωση, ανακοίνωση, διάδοση ή μετάδοση ή οποιαδήποτε άλλη χρήση του περιεχομένου της ιστοσελίδας www.psonistiri.gr με οποιοδήποτε τρόπο ή μέσο για εμπορικούς ή άλλους σκοπούς επιτρέπεται μόνο κατόπιν προηγούμενης γραπτής άδειας της από τους διαχειριστές της ιστοσελίδας www.psonistiri.gr ή οιουδήποτε άλλου νόμιμου
                    δικαιούχου πνευματικών δικαιωμάτων που περιλαμβάνει.
                </p>

                <h3>- Περιορισμός ευθύνης</h3>
                <p>
                    Η εταιρεία Psonistiri.gr δεν εγγυάται και δεν φέρει καμία ευθύνη για την ακρίβεια και την ορθότητα των πληροφοριών που λαμβάνει ο χρήστης μέσω του site της ούτε για την καθυστέρηση ή αδυναμία χρήσης του site της ή για τυχόν σφάλματα κατά τη μεταφορά δεδομένων ή πληροφοριών (καταστροφή αρχείων, αποθήκευση ιών, κλοπή δεδομένων κλπ.). Η ιστοσελίδα www.psonistiri.gr περιλαμβάνει
                    “συνδέσμους” προς άλλα web sites για τα οποία δεν φέρει καμιά ευθύνη τόσο για το περιεχόμενο τους όσο και για τους Όρους Προστασίας Προσωπικών Δεδομένων τους οποίους αυτά ακολουθούν. Όπως επίσης και για το περιεχόμενο και τις υπηρεσίες άλλων δικτυακών τόπων στους οποίους παραπέμπει μέσω άλλων συνδέσμων ή διαφημιστικών banner.
                </p>

                <h3>- Χρήση των Cookies</h3>
                <p>
                    Η ιστοσελίδα www.psonistiri.gr ενδέχεται να συγκεντρώνει στοιχεία αναγνώρισης των χρηστών της ιστοσελίδας χρησιμοποιώντας αντίστοιχες τεχνολογίες, όπως cookies ή / και την παρακολούθηση διευθύνσεων Πρωτοκόλλου Internet (IP). Τα cookies είναι μικρά αρχεία κειμένου που αποθηκεύονται στο σκληρό δίσκο κάθε επισκέπτη / χρήστη και δεν λαμβάνουν γνώση οποιουδήποτε εγγράφου ή αρχείου
                    από τον υπολογιστή του. Χρησιμοποιούνται για την διευκόλυνση πρόσβασης του επισκέπτη / χρήστη όσον αφορά στη χρησιμοποίηση συγκεκριμένων υπηρεσιών στην ιστοσελίδα www.psonistiri.gr , για στατιστικούς λόγους και προκειμένου να καθορίζονται οι περιοχές οι οποίες είναι χρήσιμες ή δημοφιλείς. Τα στοιχεία αυτά μπορεί να περιλαμβάνουν επίσης τον τύπο του φυλλομετρητή (browser) που
                    χρησιμοποιεί ο επισκέπτης / χρήστης, το είδος του υπολογιστή, το λειτουργικό του σύστημα, τους παρόχους διαδικτυακών υπηρεσιών και λοιπές πληροφορίες τέτοιου είδους. Ο επισκέπτης / πελάτης της ιστοσελίδας www.psonistiri.gr μπορεί να ρυθμίσει το πρόγραμμα του για πλοήγηση στο Διαδίκτυο (web browser) κατά τέτοιο τρόπο ώστε, είτε να τον προειδοποιεί για τη χρήση των cookies σε
                    συγκεκριμένες υπηρεσίες, είτε να μην επιτρέπει την αποδοχή της χρήσης cookies σε καμία περίπτωση. Σε περίπτωση που ο επισκέπτης / πελάτης των συγκεκριμένων υπηρεσιών και σελίδων δεν επιθυμεί την χρήση cookies για την αναγνώριση του δεν μπορεί να έχει περαιτέρω πρόσβαση στις υπηρεσίες αυτές.
                </p>

                <h3>- Πως Μπορείτε να Απενεργοποιήσετε τη Χρήση Cookies;</h3>
                <p>
                    Σε περίπτωση που επιθυμείτε να ενεργοποιήσετε ή να απενεργοποιήσετε τη χρήση cookies από τις ρυθμίσεις του φυλλομετρητή σας, αναλόγως του φυλλομετρητή σας επισκεφθείτε τις ακόλουθες ιστοσελίδες, για να λάβετε γνώση για τις απαραίτητες σχετικές ενέργειες
                    <br />
                    <a href="https://support.microsoft.com/el-gr/kb/278835">Internet Explorer</a>,<br />
                    <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">Firefox</a>,<a href="https://support.google.com/accounts/answer/61416?hl=en">Chrome</a> <br />
                    <a href="https://support.apple.com/kb/PH5042?locale=el_GR">Safari</a>,<a href="https://support.apple.com/el-gr/HT201265">Safari for iPad and iPhone</a>
                </p>

                <h3>- Όροι χρήσης της υπηρεσίας newsletter</h3>
                <p>
                    Η ιστοσελίδα www.psonistiri.gr στα πλαίσια των υπηρεσιών που παρέχει δίνει στον χρήστη / πελάτη τη δυνατότητα, εφόσον ο ίδιος το επιθυμεί, να λαμβάνει ενημερωτικά μηνύματα (newsletter) της ιστοσελίδας στο ηλεκτρονικό του ταχυδρομείο υποβάλλοντας τα στοιχεία της διεύθυνσης ηλεκτρονικού ταχυδρομείου του, στο αντίστοιχο πεδίο. Εφόσον παύσει ο χρήστης να ενδιαφέρεται για την λήψη
                    των ενημερωτικών μηνυμάτων, του δίνεται η δυνατότητα να δηλώσει την επιθυμία του για παύση της αποστολής τους μέσα από το σχετικό ηλεκτρονικό ενημερωτικό μήνυμα που του αποστέλλεται από τo psonistiri.gr.
                </p>

                <h3>- Τροποποίηση Όρων και Προϋποθέσεων</h3>
                <p>Η ιστοσελίδα www.psonistiri.gr διατηρεί το δικαίωμα να τροποποιεί μονομερώς ή να ανανεώνει τους παρόντες όρους ή/και κανόνες ή/και τις προϋποθέσεις, που γίνονται μέσω αυτής χωρίς προηγούμενη ειδοποίηση, ενημερώνοντας τους επισκέπτες με ανάρτηση των νέων όρων και προϋποθέσεων στην παρούσα ιστοσελίδα.</p>

                <h3>- Εφαρμοστέο Δίκαιο</h3>
                <p>
                    Οι παρόντες όροι και προϋποθέσεις καθώς και οποιαδήποτε τροποποίηση, αλλαγή ή αλλοίωσή τους διέπονται και συμπληρώνονται από το Ελληνικό δίκαιο, το δίκαιο της Ευρωπαϊκής Ένωσης και τις σχετικές διεθνείς συνθήκες. Οποιαδήποτε διάταξη των ανωτέρω όρων καταστεί αντίθετη προς το νόμο, παύει αυτοδικαίως να ισχύει και αφαιρείται από το παρόν χωρίς σε καμία περίπτωση να θίγεται η
                    ισχύς των λοιπών όρων. Το παρόν αποτελεί συνολική συμφωνία μεταξύ της ιστοσελίδας www.psonistiri.gr και του επισκέπτη των σελίδων και υπηρεσιών του και δε δεσμεύει παρά μόνο αυτούς. Καμία τροποποίηση των όρων αυτών δε θα λαμβάνεται υπόψη και δε θα αποτελεί τμήμα της συμφωνίας αυτής εάν δεν έχει διατυπωθεί εγγράφως και δεν έχει ενσωματωθεί σε αυτήν. Για οποιαδήποτε επικοινωνία
                    με τους «Διαχειριστές» της ιστοσελίδας www.psonistiri.gr παρακαλούμε αποστείλατε ηλεκτρονική επιστολή στην αντίστοιχη πλατφόρμα επικοινωνίας που είναι διαθέσιμη στην ιστοσελίδα. Επίσης αν έχετε διαπιστώσει οποιοδήποτε πρόβλημα στο περιεχόμενο της ιστοσελίδας που άπτεται νομικών ή ηθικών θεμάτων, ειδικότερα όσον αφορά στην αναπαραγωγή του και τη χρήση των δικαιωμάτων πνευματικής
                    ιδιοκτησίας, παρακαλείσθε να μας ειδοποιήσετε άμεσα.
                </p>
            </div>
        </Center>
    );
};
