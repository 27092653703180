/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MediaQuery, Modal } from "@mantine/core";

import { invoicesTypes, invoicesTypesName, invoicesKot, invoicesKotName, customersTypes } from "../types/EnumsElectricity";
import { FetchPrices, getAllLogos, ApiResponse, Content } from "../Api/ElectricityCostAPI";
import type { NationalAveragesList } from "../types/nationalAverages";

import { adData, rotatingBanners } from "../App";

import { NotFound } from "./404";
import { Warning } from "./Warning";

import { createStyles, Table, ScrollArea, UnstyledButton, Group, Text, TextInput, Loader, NumberInput, SegmentedControl, Button, Center } from "@mantine/core";
import { useForm } from "@mantine/form";

import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from "@tabler/icons";
import { Helmet } from "react-helmet";
import { FaQuestionCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useDisclosure } from "@mantine/hooks";
import { InterestedForOfferForm } from "../Api/interestedForm";

const flexRow: React.CSSProperties = { display: "flex", flexDirection: "row" };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const flexColumn: React.CSSProperties = { display: "flex", flexDirection: "row" };
const center: React.CSSProperties = { marginTop: "auto", marginBottom: "auto" };

const useStyles = createStyles((theme) => ({
    th: {
        padding: "0 !important",
    },
    control: {
        width: "100%",
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

        "&:hover": {
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },
    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));

const useStyles2 = createStyles((theme) => ({
    form: {
        width: "60%",
        [theme.fn.smallerThan("sm")]: {
            width: "80%",
        },
    },
    radioGroups: {
        display: "flex",
        flexDirection: "column",
        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
        },
    },
    radioGroup: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    radio: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    submitBtn: {
        marginTop: "6%",
        [theme.fn.smallerThan("sm")]: {
            marginTop: "36%",
        },
    },
}));

interface TableSortProps {
    data: Content[];
    logos: { [id: number]: string };
    stateShitWhatever: [Content | undefined, (a: Content) => void, () => void];
}

interface ThProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position="apart" style={{ display: "flex", flexDirection: "row" }}>
                    <Text weight={500} size="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}

function filterData(data: Content[], search: string) {
    const query = search.toLowerCase().trim();
    return data.filter(({ invoices: item, ...rest }) => {
        const inner = (data: any): boolean => {
            // deep-searching an object
            if (data == null) return false;

            const type = typeof data;
            const isArray = Array.isArray(data);

            switch (type) {
                case "number":
                case "string": {
                    return `${data}`.toLowerCase().includes(query);
                }
                case "object": {
                    if (isArray) {
                        for (const item of data) {
                            const res = inner(item);
                            if (res) return res;
                        }
                    } else {
                        for (const key of Object.keys(data)) {
                            const res = inner(data[key]);
                            if (res) return res;
                        }
                    }
                    break;
                }
            }

            return false;
        };

        return inner({ name: item.name, company: item.provider.name, price: rest.results.totalAmount });
        // only search for the visible data
    });
}

function sortData(data: Content[], payload: { sortBy?: (content: Content) => string | number; reversed: boolean; search: string }) {
    const { sortBy, reversed, search } = payload;

    if (!sortBy) {
        return filterData(data, search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (reversed) {
                const value = sortBy(b);
                if (typeof value === "string") {
                    return value.localeCompare(sortBy(a) as string);
                } else if (typeof value === "number") {
                    return value - (sortBy(a) as number);
                }
            }

            const value = sortBy(a);
            if (typeof value === "string") {
                return value.toLowerCase().localeCompare((sortBy(b) as string).toLowerCase(), ["el-GR", "en-US"]);
            } else if (typeof value === "number") {
                return value - (sortBy(b) as number);
            }
            return 0;
        }),
        search
    );
}

const getPriceFromContent = (content: Content) => {
    const baseAmount = content.results.commissionAmount.replace(",", ".");
    const adjustableAmount = content.results.adjustableAmount.replace(",", ".");

    return parseFloat((parseFloat(baseAmount) + parseFloat(adjustableAmount)).toFixed(2));
};

function TableSort({ data, logos, stateShitWhatever: [pkg, setPkg, open] }: TableSortProps) {
    const [search, setSearch] = useState("");
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const keySelectors = {
        company: (cnt) => cnt.invoices.provider.name,
        name: (cnt) => cnt.invoices.name,
        finalPrice: (cnt) => parseFloat(cnt.results.totalAmount.replace(",", ".")),
        govGrant: (cnt) => {
            return (cnt.invoices.name.match(/\(?[Μμ]ε [Κκ]ρατική [Εε]πιδότηση\)?/)?.[0]?.length || 0) !== 0 ? 1 : -1;
        },
        lastUpdated: (cnt) => cnt.invoices.lastModifiedDate,
    } as { [key: string]: (arg: Content) => string | number };

    const setSorting = (keySelector: (content: Content) => string | number, name: string) => {
        const reversed = name === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(name);
        setSortedData(sortData(data, { sortBy: keySelector, reversed, search }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(data, { sortBy: undefined, reversed: reverseSortDirection, search: value }));
    };

    const rows = [];

    for (const content of sortedData) {
        const grant = content.invoices.name.match(/\(?[Μμ]ε [Κκ]ρατική [Εε]πιδότηση\)?/)?.[0] || "";

        rows.push(
            <tr key={content.invoices.name + "_" + content.invoices.provider.name}>
                <td style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <img width={50} src={logos?.[content.invoices.provider.id] || ""} alt={content.invoices.provider.name} />
                    {content.invoices.provider.name}
                </td>
                <td style={{ fontSize: "0.7rem" }}>{content.invoices.name.replace(grant, "")}</td>
                <td>
                    {(() => {
                        const totalA = parseFloat(content.results.totalAmount.replace(",", "."));
                        const totalB = getPriceFromContent(content);

                        return <>{totalA !== totalB ? totalB : totalA}</>;
                    })()}
                    €
                </td>
                <td>
                    <span
                        style={{ cursor: "pointer", textDecoration: "underline", color: "orange", userSelect: "none", textAlign: "center", alignItems: "center", alignContent: "center" }}
                        onClick={() => {
                            setPkg(content);
                            open();
                        }}
                    >
                        Δήλωση ενδιαφέροντος
                    </span>
                </td>
                <td>{grant.length ? "Ναί" : "Όχι"}</td>
                <td>{content.results.fixedAmount}€</td>

                <td>{new Date(content.invoices.lastModifiedDate).toLocaleDateString()}</td>
            </tr>
        );
    }

    return (
        <Center style={{ display: "flex", flexDirection: "column" }}>
            <TextInput style={{ width: "90%" }} placeholder="Αναζήτηση βάση εταιρείας ή πακέτου" mb="md" icon={<IconSearch size={14} stroke={1.5} />} value={search} onChange={handleSearchChange} />
            <ScrollArea style={{ maxWidth: "100vw", border: "2px solid orange" }}>
                <Table horizontalSpacing="xs" verticalSpacing="xs" sx={{ tableLayout: "fixed", minWidth: 700 }}>
                    <thead>
                        <tr>
                            <Th sorted={sortBy === "company"} reversed={reverseSortDirection} onSort={() => setSorting(keySelectors.company, "company")}>
                                Εταιρεία
                            </Th>
                            <Th sorted={sortBy === "name"} reversed={reverseSortDirection} onSort={() => setSorting(keySelectors.name, "name")}>
                                Πακέτο
                            </Th>
                            <Th sorted={sortBy === "finalPrice"} reversed={reverseSortDirection} onSort={() => setSorting(keySelectors.finalPrice, "finalPrice")}>
                                Τελική Τιμή
                            </Th>
                            <th></th>
                            <Th sorted={sortBy === "govGrant"} reversed={reverseSortDirection} onSort={() => setSorting(keySelectors.govGrant, "govGrant")}>
                                Κρατική Επιδότηση;
                            </Th>
                            <th>Πάγιο</th>
                            <Th sorted={sortBy === "lastUpdated"} reversed={reverseSortDirection} onSort={() => setSorting(keySelectors.lastUpdated, "lastUpdated")}>
                                Τελευταία Ενημέρωση
                            </Th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={Object.keys(data[0]).length}>
                                    <Text weight={500} align="center">
                                        0 αποτελέσματα
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>
        </Center>
    );
}

function shuffle(array: any[]) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function PriceExplorerElectricity() {
    const { who } = useParams();

    const [doneFetchingData, setDoneFetchingData] = useState(1);
    const [scrapedData, setScrapedData] = useState<ApiResponse>();
    const [nationalAverage, setNationalAverage] = useState<NationalAveragesList>();
    const [nationalAverageDisabled, setNationalAverageDisabled] = useState(false);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [img, setImg] = useState("");
    const [isImgModalOpen, { open: imgModalOpen, close: imgModalClose }] = useDisclosure(false);
    const [isInterestedModalOpen, { open: interestedModalOpen, close: interestedModalClose }] = useDisclosure(false);
    const [interestedContent, setInterestedContent] = useState<Content>();

    const form = useForm({
        initialValues: {
            klwh: 250,
            days: 30,
            klwhNight: 90,
            providerType: "monofasiko",
            kok: "false",
            night: "false",
            existingSubscriber: "false",
        },
    });

    const [power, setPower] = useState(8);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [logos, setLogos] = useState<{ [id: number]: string }>({});
    const { classes } = useStyles2();

    const updateFieldsWithNationalAverageData = (provisionType: "F1" | "F3", typesId: number, electricityChargeDaysTypeId: number, json: NationalAveragesList) => {
        const averages = json.data.electricityAverageValueList.filter((item) => item.id.provisionId === provisionType && item.id.typesId === typesId && item.id.electricityChargeDaysTypeId === electricityChargeDaysTypeId);

        if (averages.length !== 1) {
            throw new Error("Impossible condition reached!");
        }

        form.setFieldValue("klwh", parseInt(averages[0].averageValueDay.replace(".", "").replace(",", ".")));
        form.setFieldValue("klwhNight", parseInt(averages[0].averageValueNight.replace(".", "").replace(",", ".")));
    };

    const [mobileAdBanners, setMobileAdBanners] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const bannerIds = adData.mobileHorizontal.bannerIds.filter((banner) => banner.expiresAt === undefined || Date.now() < (banner.expiresAt as Date).getTime());
        shuffle(bannerIds);

        setMobileAdBanners(() =>
            [rotatingBanners.mobileCenter, rotatingBanners.mobileCenter, rotatingBanners.mobileCenter].map((b, i) => {
                return <iframe style={{ zIndex: "6", width: `468px`, height: `90px`, transform: "scale(0.8)" }} src={b!!.baseLink.replace("{ID}", bannerIds[i].bannerId)} scrolling="no" frameBorder="0"></iframe>;
            })
        );
    }, []);

    if (!["home", "business"].includes(who!!)) {
        return <NotFound />;
    }

    return (
        <div style={{ fontSize: "1rem" }}>
            <Helmet>
                <title>Ηλεκτρικό Ρεύμα - {who === "home" ? "Οικιακό" : "Επαγγελματικό"} - psonistiri.gr</title>
            </Helmet>

            <ReactTooltip place="top" type="dark" effect="solid" html={true} />

            <Modal
                size="auto"
                opened={isImgModalOpen}
                onClose={() => {
                    imgModalClose();
                    setImg("");
                }}
                overflow="inside"
            >
                <img src={img} style={{ width: "85vw" }} alt="" />
            </Modal>

            <Modal size="auto" opened={isInterestedModalOpen} onClose={interestedModalClose} overflow="inside">
                <InterestedForOfferForm closeModal={interestedModalClose} content={interestedContent} setContent={setInterestedContent} scrapedData={scrapedData!!} />
            </Modal>

            <MediaQuery query="(min-width: 668px)" styles={{ display: "none" }}>
                <Center style={{ marginTop: "5%", marginBottom: "5%" }}>
                    <div style={{ width: "80%", position: "absolute", left: "calc(50% - calc(468px / 2))" }}>{mobileAdBanners?.[0]}</div>
                </Center>
            </MediaQuery>

            <div className={classes.radio}>
                <h5 style={{ marginBottom: "0px", marginTop: "5%" }}>Χρήση του εθνικού μέσου όρου;</h5>
                <SegmentedControl
                    style={{ backgroundColor: "white", border: "2px solid black" }}
                    color="orange"
                    disabled={nationalAverageDisabled}
                    data={[
                        { label: "Ναί", value: "true" },
                        { label: "Όχι", value: "false" },
                    ]}
                    defaultValue={"false"}
                    onInput={(e) => {
                        const elem = e.target as HTMLInputElement;
                        if (elem.tagName === "INPUT") {
                            const flag = (elem as HTMLInputElement).value === "true";
                            const provisionType = form.values.providerType === "trifasiko" ? "F3" : "F1";

                            // prettier-ignore
                            const typesId = [ [4, 5], [0, 1] ]
                                [((who === "home") as any as number) + 0]
                                [((form.values.night === "true") as any as number) + 0];

                            const days = Math.floor(form.values.days);
                            const electricityChargeDaysTypeId = days <= 50 ? 0 : days <= 150 ? 1 : 2;

                            if (flag) {
                                setFieldsDisabled(true);
                                if (nationalAverage === undefined) {
                                    setNationalAverageDisabled(true);
                                    fetch("https://www.energycost.gr/rae-services/public/electricity/invoice/lookupdata")
                                        .then((res) => res.json())
                                        .then((json) => {
                                            setNationalAverage(json);
                                            setNationalAverageDisabled(false);
                                            return json;
                                        })
                                        .then((json) => updateFieldsWithNationalAverageData(provisionType, typesId, electricityChargeDaysTypeId, json))
                                        .catch(console.error);
                                } else {
                                    updateFieldsWithNationalAverageData(provisionType, typesId, electricityChargeDaysTypeId, nationalAverage);
                                }
                            } else {
                                setFieldsDisabled(false);
                            }
                        }
                    }}
                />
            </div>

            <div className={classes.form} style={{ margin: "auto" }}>
                <div style={flexRow}>
                    <NumberInput
                        defaultValue={30}
                        placeholder="πχ: 30"
                        label="Ημέρες κατανάλωσης"
                        description="Ιδανικά καταχωρήστε τις ημέρες κατανάλωσης του περσινού εκαθαρριστικού λογαριασμού σας, της ίδιας περιόδου."
                        radius="md"
                        {...form.getInputProps("days")}
                        min={0}
                        withAsterisk
                        style={{ ...center, flexGrow: 1 }}
                        onInput={(e) => {
                            if (!fieldsDisabled || nationalAverage === undefined) return;

                            const elem = e.target as HTMLInputElement;
                            if (elem.tagName !== "INPUT") return;

                            // prettier-ignore
                            const provisionType = form.values.providerType === "trifasiko" ? "F3" : "F1";

                            // prettier-ignore
                            const typesId = [ [4, 5], [0, 1] ]
                                [((who === "home") as any as number) + 0]
                                [((form.values.night === "true") as any as number) + 0];

                            const days = Math.floor(parseFloat(elem.value.replace(".", ",").replace(",", ".")) || 30);
                            const electricityChargeDaysTypeId = days <= 50 ? 0 : days <= 150 ? 1 : 2;

                            updateFieldsWithNationalAverageData(provisionType, typesId, electricityChargeDaysTypeId, nationalAverage);
                        }}
                    />
                    <button
                        style={{ ...center, transform: "translate(20%, 75%)" }}
                        onClick={() => {
                            setImg("/resources/consumption-days.png");
                            imgModalOpen();
                        }}
                    >
                        <FaQuestionCircle data-tip="" />
                    </button>
                </div>

                <div style={flexRow}>
                    <NumberInput
                        defaultValue={250}
                        placeholder="πχ: 250"
                        disabled={fieldsDisabled}
                        label={(form.values.night === "true" ? "Ημερήσιες " : "") + "Κιλοβατώρες"}
                        description="Ιδανικά καταχωρήστε τις κιλοβατώρες κατανάλωσης του περσινού εκαθαρριστικού λογαριασμού σας, της ίδιας περιόδου."
                        radius="md"
                        {...form.getInputProps("klwh")}
                        min={0}
                        step={50}
                        decimalSeparator=","
                        precision={2}
                        withAsterisk
                        style={{ ...center, flexGrow: 1 }}
                    />

                    {form.values.night === "false" && (
                        <button
                            style={{ ...center, transform: "translate(20%, 75%)" }}
                            onClick={() => {
                                setImg("/resources/kilovatores.png");
                                imgModalOpen();
                            }}
                        >
                            <FaQuestionCircle data-tip="" />
                        </button>
                    )}
                </div>

                {form.values.night === "true" && <NumberInput defaultValue={90} placeholder="πχ: 90" disabled={fieldsDisabled} label="Νυκτερινές Κιλοβατώρες" radius="md" {...form.getInputProps("klwhNight")} min={0} step={10} decimalSeparator="," precision={2} withAsterisk />}

                {form.values.night === "true" && <p>Συνολικές Κιλοβατώρες: {(form.values.klwh + form.values.klwhNight).toLocaleString("el-GR")}</p>}

                <div className={classes.radioGroups}>
                    <div className={classes.radioGroup}>
                        <div className={classes.radio}>
                            <h5 style={{ marginBottom: "0px", marginTop: "5%" }}>Επιθυμείτε να δείτε τις προσφορές των εταιριών για το υπάρχον πελατολόγιο;</h5>
                            <SegmentedControl
                                color="orange"
                                style={{ backgroundColor: "white", border: "2px solid black" }}
                                {...form.getInputProps("existingSubscriber")}
                                title="Προσφορές για υπάρχον πελάτες;"
                                data={[
                                    { label: "Ναί", value: "true" },
                                    { label: "Όχι", value: "false" },
                                ]}
                            />
                        </div>
                        <div className={classes.radio}>
                            <h5 style={{ marginBottom: "0px", marginTop: "5%" }}>Παρακαλούμε επιλέξτε τον τύπο της παροχή σας:</h5>
                            <SegmentedControl
                                {...form.getInputProps("providerType")}
                                style={{
                                    backgroundColor: "white",
                                    border: "2px solid black",
                                }}
                                color="orange"
                                title="Τύπος Παροχής"
                                data={[
                                    { label: "Μονοφασικό", value: "monofasiko" },
                                    { label: "Τριφασικό", value: "trifasiko" },
                                ]}
                                onChange={(value) => {
                                    if (value === form.values.providerType) return;
                                    form.setFieldValue("providerType", value);
                                    setPower(value === "monofasiko" ? 8 : 15);
                                }}
                                onInput={(e) => {
                                    if (!fieldsDisabled || nationalAverage === undefined) return;

                                    const elem = e.target as HTMLInputElement;
                                    if (elem.tagName !== "INPUT") return;
                                    if (!fieldsDisabled || nationalAverage === undefined) return;

                                    const provisionType = elem.value === "trifasiko" ? "F3" : "F1";

                                    // prettier-ignore
                                    const typesId = [ [4, 5], [0, 1] ]
                                            [((who === "home") as any as number) + 0]
                                            [((form.values.night === "true") as any as number) + 0];

                                    const days = Math.floor(form.values.days);
                                    const electricityChargeDaysTypeId = days <= 50 ? 0 : days <= 150 ? 1 : 2;

                                    updateFieldsWithNationalAverageData(provisionType, typesId, electricityChargeDaysTypeId, nationalAverage);
                                }}
                            />
                        </div>
                    </div>

                    <div className={classes.radioGroup}>
                        <div className={classes.radio}>
                            <h5 style={{ marginBottom: "0px", marginTop: "5%" }}>Είστε δικαιούχοι κοινωνικού τιμολογίου;</h5>
                            <SegmentedControl
                                color="orange"
                                style={{ backgroundColor: "white", border: "2px solid black" }}
                                {...form.getInputProps("kok")}
                                data={[
                                    { label: "Ναί", value: "true" },
                                    { label: "Όχι", value: "false" },
                                ]}
                            />
                        </div>
                        <div className={classes.radio}>
                            <h5 style={{ marginBottom: "0px", marginTop: "5%" }}>Έχετε Νυκτερινό Τιμολόγιο;</h5>
                            <SegmentedControl
                                style={{ backgroundColor: "white", border: "2px solid black" }}
                                color="orange"
                                {...form.getInputProps("night")}
                                title="Νυκτερινό Τιμολόγιο"
                                data={[
                                    { label: "Ναί", value: "true" },
                                    { label: "Όχι", value: "false" },
                                ]}
                                onInput={(e) => {
                                    if (!fieldsDisabled || nationalAverage === undefined) return;

                                    const elem = e.target as HTMLInputElement;
                                    if (elem.tagName !== "INPUT") return;
                                    if (!fieldsDisabled || nationalAverage === undefined) return;

                                    // prettier-ignore
                                    const provisionType = form.values.providerType === "trifasiko" ? "F3" : "F1";

                                    // prettier-ignore
                                    const typesId = [ [4, 5], [0, 1] ]
                                            [((who === "home") as any as number) + 0]
                                            [((elem.value === "true") as any as number) + 0];

                                    const days = Math.floor(form.values.days);
                                    const electricityChargeDaysTypeId = days <= 50 ? 0 : days <= 150 ? 1 : 2;

                                    updateFieldsWithNationalAverageData(provisionType, typesId, electricityChargeDaysTypeId, nationalAverage);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div style={flexRow}>
                    <NumberInput
                        style={{ ...center, flexGrow: 1 }}
                        value={power}
                        onChange={(val) => val !== undefined && setPower(val)}
                        defaultValue={8}
                        placeholder="πχ: 8 kVA"
                        label="Ισχύς Παροχής (kVA)"
                        description="Για μονοφασικό ρεύμα, η ισχής συνήθως είναι είτε 8 kVA είτε 10 kVA. Για τριφασικό ρεύμα, η ισχύς συνήθως είναι είτε 15 KVA είτε 25 kVA."
                        radius="md"
                        min={8}
                        step={2}
                        decimalSeparator=","
                        precision={2}
                    />
                    <button
                        style={{ ...center, transform: "translate(20%, 75%)" }}
                        onClick={() => {
                            setImg("/resources/provider-type-and-power.png");
                            imgModalOpen();
                        }}
                    >
                        <FaQuestionCircle data-tip="" />
                    </button>
                </div>

                <MediaQuery query="(min-width: 668px)" styles={{ display: "none" }}>
                    <div style={{ position: "absolute", left: "calc(50% - calc(468px / 2))", marginTop: "5%" }}>{mobileAdBanners?.[1]}</div>
                </MediaQuery>

                <div className={classes.submitBtn} style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }}>
                    <Center>
                        <Button
                            disabled={buttonDisabled}
                            style={{ width: "35%", marginBottom: "5%" }}
                            onClick={() => {
                                if (Object.keys(logos).length === 0) {
                                    getAllLogos().then(setLogos);
                                }
                                setButtonDisabled(true);
                                setScrapedData(undefined);
                                setDoneFetchingData(0);

                                const isNight = form.values.night === "true";
                                const kage = who!! + "_" + isNight;

                                const invoiceTypeName =
                                    {
                                        home_false: invoicesTypesName.Οικιακό_Ημερήσιο,
                                        home_true: invoicesTypesName.Οικιακό_Νυχτερινό,
                                        business_false: invoicesTypesName.Επαγγελματικό_Μικρή,
                                        business_true: invoicesTypesName.Επαγγελματικό_Νυχτερινό,
                                    }[kage] + "";

                                const invoiceTypeId = {
                                    home_false: invoicesTypes.Οικιακό_Ημερήσιο,
                                    home_true: invoicesTypes.Οικιακό_Νυχτερινό,
                                    business_false: invoicesTypes.Επαγγελματικό_Μικρή,
                                    business_true: invoicesTypes.Επαγγελματικό_Νυχτερινό,
                                }[kage] as number;

                                const kotId = (form.values.kok === "true" ? invoicesKot.ΚΟΤΑ_and_ΚΟΤΒ : invoicesKot.Όχι) as number;
                                const kotName = (form.values.kok === "true" ? invoicesKotName.ΚΟΤΑ_and_ΚΟΤΒ : invoicesKotName.Όχι) as string;

                                const provisionType = form.values.providerType === "trifasiko" ? "F3" : "F1";

                                FetchPrices({
                                    pageIndex: 1,
                                    pageSize: 1666666,
                                    sortField: "totalAmount",
                                    sortOrder: "ASC",
                                    excludedIdsList: null,
                                    criteria: {
                                        invoiceType: {
                                            id: invoiceTypeId,
                                            name: invoiceTypeName,
                                            description: null,
                                            isProfessional: [5, 6].includes(invoiceTypeId),
                                            isNight,
                                        },
                                        agreedPower: power,
                                        consumptionDay: form.values.klwh,
                                        consumptionNight: form.values.night === "true" ? form.values.klwhNight : 0,
                                        consumptionDays: form.values.days || 30,
                                        minConsumptionLimit: 0,
                                        defaultValues: false,
                                        maxConsumptionLimit: 0,
                                        discount: 0,
                                        provisionType: provisionType,
                                        deservesKot: form.values.kok === "true",
                                        kot: {
                                            id: kotId,
                                            name: kotName,
                                        },
                                        hasWithdrawment: false, // ρήτρα
                                        hasOts: null,
                                        hasCo2: false,
                                        discountTypesList: [0, 1, 2, 3, 4, 5, 6],
                                        discountCustomerTypes: [form.values.existingSubscriber === "true" ? customersTypes.EXISTING : customersTypes.NEW],
                                        otsClauseCustomerWantInputNumber: false,
                                        otsClauseInputNumber: "",
                                        co2ClauseCustomerWantInputNumber: false,
                                        co2ClauseInputNumber: "",
                                    },
                                })
                                    .then((data) => {
                                        // console.log(JSON.stringify(data));
                                        setScrapedData(data);
                                        setDoneFetchingData(1);
                                        setButtonDisabled(false);
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        setButtonDisabled(false);
                                    });
                            }}
                        >
                            Σύγκριση
                        </Button>
                    </Center>

                    {doneFetchingData === 0 && (
                        <Center>
                            <Loader size="xl" />
                        </Center>
                    )}
                </div>
            </div>

            {scrapedData && (
                <div>
                    <Warning msg="Ο παρακάτω τιμοκατάλογος δεν συμπεριλαμβάνει πακέτα που απαιτούν ρήτρα πρόωρης αποχώρησης ή πακέτα που απαιτούν ρήτρα εκπομπών CO2." />
                    <TableSort logos={logos} data={Array.from(scrapedData.data.content)} stateShitWhatever={[interestedContent, setInterestedContent, interestedModalOpen]} />
                </div>
            )}
            <MediaQuery query="(min-width: 668px)" styles={{ display: "none" }}>
                <div style={{ position: "absolute", left: "calc(50% - calc(468px / 2))", marginTop: "5%", marginBottom: "15%" }}>{mobileAdBanners?.[2]}</div>
            </MediaQuery>
        </div>
    );
}
