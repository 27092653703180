/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useRef, useState } from "react";

import { FetchPrices } from "../Api/GasCostApi";
import { provisionTypes, provisionTypesName, customersTypes } from "../types/EnumsGas";

import { getAllLogos } from "../Api/ElectricityCostAPI";
import { Networks } from "../Api/GasCostApi";
import type { ApiResponse, Content } from "../Api/GasCostApi";
import { createStyles, Table, ScrollArea, UnstyledButton, Group, Text, TextInput, Loader, NumberInput, SegmentedControl, Button, Center, Select, MediaQuery } from "@mantine/core";
import { useForm } from "@mantine/form";
import { adData, rotatingBanners } from "../App";

import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from "@tabler/icons";
import { Helmet } from "react-helmet";

import ReactTooltip from "react-tooltip";
import { NationalAveragesList } from "../types/nationalAverages";

const useStyles = createStyles((theme) => ({
    th: {
        padding: "0 !important",
        fontSize: "0.9rem",
    },
    control: {
        width: "100%",
        padding: `${theme.spacing.xs}px ${theme.spacing.xs}px`,

        "&:hover": {
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },
    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));

interface TableSortProps {
    data: Content[];
    logos: { [id: number]: string };
}

interface ThProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position="apart">
                    <Text weight={500} size="xs">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}

function filterData(data: Content[], search: string) {
    const query = search.toLowerCase().trim();
    return data.filter(({ invoices: item, ...rest }) => {
        const inner = (data: any): boolean => {
            // deep-searching an object
            if (data == null) return false;

            const type = typeof data;
            const isArray = Array.isArray(data);

            switch (type) {
                case "number":
                case "string": {
                    return `${data}`.toLowerCase().includes(query);
                }
                case "object": {
                    if (isArray) {
                        for (const item of data) {
                            const res = inner(item);
                            if (res) return res;
                        }
                    } else {
                        for (const key of Object.keys(data)) {
                            const res = inner(data[key]);
                            if (res) return res;
                        }
                    }
                    break;
                }
            }

            return false;
        };

        return inner({ name: item.name, company: item.provider.name, price: rest.results.totalAmount });
        // only search for the visible data
    });
}

function sortData(data: Content[], payload: { sortBy?: (content: Content) => string | number; reversed: boolean; search: string }) {
    const { sortBy, reversed, search } = payload;

    if (!sortBy) {
        return filterData(data, search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (reversed) {
                const value = sortBy(b);
                if (typeof value === "string") {
                    return value.localeCompare(sortBy(a) as string);
                } else if (typeof value === "number") {
                    return value - (sortBy(a) as number);
                }
            }

            const value = sortBy(a);
            if (typeof value === "string") {
                return value.toLowerCase().localeCompare((sortBy(b) as string).toLowerCase(), ["el-GR", "en-US"]);
            } else if (typeof value === "number") {
                return value - (sortBy(b) as number);
            }
            return 0;
        }),
        search
    );
}

function TableSort({ data, logos }: TableSortProps) {
    const [search, setSearch] = useState("");
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const keySelectors = {
        company: (cnt) => cnt.invoices.provider.name,
        name: (cnt) => cnt.invoices.name,
        finalPrice: (cnt) => parseFloat(cnt.results.totalAmount.replace(",", ".")),
    } as { [key: string]: (arg: Content) => string | number };

    const setSorting = (keySelector: (content: Content) => string | number, name: string) => {
        const reversed = name === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(name);
        setSortedData(sortData(data, { sortBy: keySelector, reversed, search }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(data, { sortBy: undefined, reversed: reverseSortDirection, search: value }));
    };

    const rows = [];

    for (const content of sortedData) {
        rows.push(
            <tr key={content.invoices.name + "_" + content.invoices.provider.name}>
                <td style={{ fontSize: "0.6rem", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <img width={50} src={logos?.[content.invoices.provider.id] || ""} alt={content.invoices.provider.name} />
                    {content.invoices.provider.name}
                </td>
                <td style={{ fontSize: "0.6rem" }}>{content.invoices.name}</td>
                <td style={{ fontSize: "0.6rem" }}>{content.results.totalAmount}€</td>
                <td style={{ fontSize: "0.6rem" }}>{content.results.fixedAmount}€</td>
            </tr>
        );
    }

    return (
        <Center style={{ display: "flex", flexDirection: "column" }}>
            <TextInput style={{ width: "90%" }} placeholder="Αναζήτηση βάση εταιρείας ή πακέτου" mb="md" icon={<IconSearch size={14} stroke={1.5} />} value={search} onChange={handleSearchChange} />
            <ScrollArea style={{ maxWidth: "100vw", border: "2px solid orange" }}>
                <Table horizontalSpacing="xs" verticalSpacing="xs" sx={{ tableLayout: "fixed", minWidth: 700 }}>
                    <thead>
                        <tr>
                            <Th sorted={sortBy === "company"} reversed={reverseSortDirection} onSort={() => setSorting(keySelectors.company, "company")}>
                                Εταιρεία
                            </Th>
                            <Th sorted={sortBy === "name"} reversed={reverseSortDirection} onSort={() => setSorting(keySelectors.name, "name")}>
                                Πακέτο
                            </Th>
                            <Th sorted={sortBy === "finalPrice"} reversed={reverseSortDirection} onSort={() => setSorting(keySelectors.finalPrice, "finalPrice")}>
                                Τελική Τιμή
                            </Th>
                            <th>Πάγιο</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </ScrollArea>
        </Center>
    );
}

const months = ["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάιος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"];

const useStyles2 = createStyles((theme) => ({
    form: {
        width: "60%",
        [theme.fn.smallerThan("sm")]: {
            width: "80%",
        },
    },
    radioGroups: {
        display: "flex",
        flexDirection: "column",
        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
        },
    },
    radioGroup: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    radio: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    submitBtn: {
        marginTop: "6%",
        [theme.fn.smallerThan("sm")]: {
            marginTop: "36%",
        },
    },
}));

function shuffle(array: any[]) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function PriceExplorerGas() {
    const [scrapedData, setScrapedData] = useState<ApiResponse>();

    const [doneFetchingData, setDoneFetchingData] = useState(1);
    const [logos, setLogos] = useState<{ [id: number]: string }>({});
    const { classes } = useStyles2();

    const form = useForm({
        initialValues: {
            klwh: 600,
            provisionType: provisionTypesName.AUTONOMUS.toLowerCase(),
            existingSubscriber: "false",
            month: "0",
            area: "1",
        },
    });

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const provisionTypeRef = useRef();

    const [nationalAverage, setNationalAverage] = useState<NationalAveragesList>();
    const [nationalAverageDisabled, setNationalAverageDisabled] = useState(false);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);

    useEffect(() => {
        const central = (provisionTypeRef?.current as any as HTMLElement)?.querySelector(".mantine-SegmentedControl-control:nth-child(2)");
        (central as any).dataset.tip = "Η τροφοδοσία αφορά κεντρική παροχή πολυκατοικίας";
    }, []);

    const updateFieldsWithNationalAverageData = (provisionId: number, gasChargeDaysTypeId: 1 | 2, json: NationalAveragesList) => {
        const averages = json.data.gasAverageValueList.filter((item) => item.id.provisionId === provisionId && item.id.gasChargeDaysTypeId === gasChargeDaysTypeId);

        if (averages.length !== 1) {
            console.error(new Error("Impossible condition reached!"), averages);
            return;
        }

        form.setFieldValue("klwh", parseFloat(averages[0].averageValue.replace(".", "").replace(",", ".")));
    };

    const [mobileAdBanners, setMobileAdBanners] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const bannerIds = adData.mobileHorizontal.bannerIds.filter((banner) => banner.expiresAt === undefined || Date.now() < (banner.expiresAt as Date).getTime());
        shuffle(bannerIds);

        setMobileAdBanners(() =>
            [rotatingBanners.mobileCenter, rotatingBanners.mobileCenter, rotatingBanners.mobileCenter].map((b, i) => {
                return <iframe style={{ zIndex: "6", width: `468px`, height: `90px`, transform: "scale(0.8)" }} src={b!!.baseLink.replace("{ID}", bannerIds[i].bannerId)} scrolling="no" frameBorder="0"></iframe>;
            })
        );
    }, []);

    return (
        <div>
            <Helmet>
                <title>Φυσικό Αέριο - psonistiri.gr</title>
            </Helmet>

            <MediaQuery query="(min-width: 668px)" styles={{ display: "none" }}>
                <Center style={{ marginTop: "5%", marginBottom: "5%" }}>
                    <div style={{ width: "80%", position: "absolute", left: "calc(50% - calc(468px / 2))" }}>{mobileAdBanners?.[0]}</div>
                </Center>
            </MediaQuery>

            <div className={classes.radio}>
                <h5 style={{ marginBottom: "0px", marginTop: "5%" }}>Χρήση του εθνικού μέσου όρου;</h5>
                <SegmentedControl
                    style={{ backgroundColor: "white", border: "2px solid black" }}
                    color="orange"
                    disabled={nationalAverageDisabled}
                    data={[
                        { label: "Ναί", value: "true" },
                        { label: "Όχι", value: "false" },
                    ]}
                    defaultValue={"false"}
                    onInput={(e) => {
                        const elem = e.target as HTMLInputElement;
                        if (elem.tagName === "INPUT") {
                            const flag = (elem as HTMLInputElement).value === "true";

                            // prettier-ignore
                            const provisionId = [
                                provisionTypesName.CENTRAL,
                                provisionTypesName.AUTONOMUS,
                                provisionTypesName.PROFESSIONAL
                            ].indexOf(form.values.provisionType.toUpperCase() as provisionTypesName);

                            if (flag) {
                                setFieldsDisabled(true);
                                if (nationalAverage === undefined) {
                                    setNationalAverageDisabled(true);
                                    fetch("https://www.energycost.gr/rae-services/public/gas/invoice/lookupdata")
                                        .then((res) => res.json())
                                        .then((json) => {
                                            setNationalAverage(json);
                                            setNationalAverageDisabled(false);
                                            return json;
                                        })
                                        .then((json) => updateFieldsWithNationalAverageData(provisionId, 1 /* we use '1' because the number of the days you can select is frozen to '30', and '30' => '1' */, json))
                                        .catch(console.error);
                                } else {
                                    updateFieldsWithNationalAverageData(provisionId, 1, nationalAverage);
                                }
                            } else {
                                setFieldsDisabled(false);
                                // restore to the default values
                            }
                        }
                    }}
                />
            </div>

            <div style={{ maxWidth: 380, margin: "auto" }}>
                <NumberInput disabled={fieldsDisabled} defaultValue={600} placeholder="πχ: 600" label="Κιλοβατώρες" description="Ιδανικά καταχωρήστε τις κιλοβατώρες που καταναλώσατε το προηγούμενο έτος για " radius="md" {...form.getInputProps("klwh")} min={0} step={50} decimalSeparator="," precision={2} withAsterisk />

                <Select {...form.getInputProps("month")} label={"Μήνας αναφοράς:"} defaultValue={"0"} data={months.map((month, index) => ({ label: month, value: `${index}` }))} />

                <Center style={{ display: "flex", flexDirection: "column" }}>
                    <h5 style={{ marginBottom: "1%", marginTop: "5%" }}>Παρακαλούμε επιλέξτε τον τύπο της εγκατάστασής του φυσικού αερίου:</h5>
                    <SegmentedControl
                        ref={provisionTypeRef}
                        {...form.getInputProps("provisionType")}
                        style={{ backgroundColor: "white", border: "2px solid black" }}
                        color="orange"
                        data={[
                            { label: "Οικιακή", value: provisionTypesName.AUTONOMUS.toLowerCase() },
                            { label: "Κεντρική", value: provisionTypesName.CENTRAL.toLowerCase() },
                            { label: "Επαγγελματική", value: provisionTypesName.PROFESSIONAL.toLowerCase() },
                        ]}
                        onInput={(e) => {
                            if (!fieldsDisabled || nationalAverage === undefined) return;

                            const elem = e.target as HTMLInputElement;
                            if (elem.tagName !== "INPUT") return;

                            // prettier-ignore
                            const provisionId = [
                                provisionTypesName.CENTRAL,
                                provisionTypesName.AUTONOMUS,
                                provisionTypesName.PROFESSIONAL
                            ].indexOf(elem.value.toUpperCase() as provisionTypesName);

                            updateFieldsWithNationalAverageData(provisionId, 1, nationalAverage);
                        }}
                    />
                </Center>
                <ReactTooltip place="top" type="dark" effect="solid" />

                <div className={classes.radioGroup}>
                    <div>
                        <h5 style={{ marginBottom: "0px", marginTop: "5%" }}>Επιθυμείτε να δείτε τις προσφορές των εταιριών για το υπάρχον πελατολόγιο;</h5>
                        <SegmentedControl
                            style={{ marginLeft: "25%", backgroundColor: "white", border: "2px solid black" }}
                            {...form.getInputProps("existingSubscriber")}
                            color="orange"
                            data={[
                                { label: "Ναί", value: "true" },
                                { label: "Όχι", value: "false" },
                            ]}
                        />
                    </div>
                </div>

                <Select {...form.getInputProps("area")} label="Δίκτυο διανομής/Περιοχή" placeholder="//" defaultValue={`${Networks.find((net) => net.id === 1)?.id || Networks[0]?.id}`} data={Networks.map((net) => ({ label: net.code, value: `${net.id}` }))} style={{ marginBottom: "5%" }} />

                <MediaQuery query="(min-width: 668px)" styles={{ display: "none" }}>
                    <div style={{ position: "absolute", left: "calc(50% - calc(468px / 2))", marginTop: "5%" }}>{mobileAdBanners?.[1]}</div>
                </MediaQuery>

                <div className={classes.submitBtn} style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }}>
                    <Center style={{ marginBottom: "5%" }}>
                        <Button
                            disabled={buttonDisabled}
                            style={{ width: "35%" }}
                            onClick={() => {
                                if (Object.keys(logos).length === 0) {
                                    getAllLogos().then(setLogos);
                                }
                                setButtonDisabled(true);
                                setScrapedData(undefined);
                                setDoneFetchingData(0);

                                FetchPrices({
                                    pageIndex: 1,
                                    pageSize: 999,
                                    sortField: "totalAmount",
                                    sortOrder: "ASC",
                                    excludedIdsList: null,
                                    criteria: {
                                        network: Networks.find((net) => `${net.id}` === form.values.area) || Networks.find((net) => net.id === 1)!!,
                                        month: parseInt(form.values.month || "0") + 1,
                                        provisionType: {
                                            id: ["0", provisionTypesName.AUTONOMUS, provisionTypesName.CENTRAL, provisionTypesName.PROFESSIONAL].indexOf(form.values.provisionType.toUpperCase()) as provisionTypes,
                                            name: form.values.provisionType.toUpperCase() as provisionTypesName,
                                        },
                                        consumption: parseFloat(`${form.values.klwh}`),
                                        chargeDays: 30,
                                        defaultValues: false,
                                        load: "150",
                                        hasWithdrawment: true,
                                        discountTypesList: [0, 1, 2, 3, 4, 5],
                                        discountCustomerTypesList: [form.values.existingSubscriber === "true" ? customersTypes.EXISTING : customersTypes.NEW],
                                    },
                                })
                                    .then((data) => {
                                        // console.log(JSON.stringify(data.data.content[0]));
                                        setScrapedData(data);
                                        setDoneFetchingData(1);
                                        setButtonDisabled(false);
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        setButtonDisabled(false);
                                    });
                            }}
                        >
                            Σύγκριση
                        </Button>
                    </Center>

                    {doneFetchingData === 0 && (
                        <Center>
                            <Loader color="indigo" size="xl" />
                        </Center>
                    )}
                </div>
            </div>

            {scrapedData && <TableSort logos={logos} data={Array.from(scrapedData.data.content)} />}

            <MediaQuery query="(min-width: 668px)" styles={{ display: "none" }}>
                <div style={{ position: "absolute", left: "calc(50% - calc(468px / 2))", marginTop: "5%", marginBottom: "15%" }}>{mobileAdBanners?.[2]}</div>
            </MediaQuery>
        </div>
    );
}
