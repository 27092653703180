import { Button, Center, createStyles, Select, SimpleGrid, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import { ApiResponse as ElectricityApiResponse, Content as ElectricityContent } from "./ElectricityCostAPI";
import { ApiResponse as GasApiResponse, Content as GasContent } from "./GasCostApi";

type Content = ElectricityContent | GasContent;
type ApiResponse = ElectricityApiResponse | GasApiResponse;

const submitUrl = "https://docs.google.com/forms/d/e/1FAIpQLSd-P27LM_jvWe4lclqQ4fEgs8OSS0-DrfDu6KUX0_esoL0z_w/formResponse";

export enum FormField {
    phoneNumber = "entry.715509460",
    json = "entry.1348437797",
    emailAddress = "entry.748734934",
    fullName = "entry.906618261",
}

interface Props {
    fullName: string;
    content: Content | undefined;
    emailAddress: string;
    phoneNumber: string;
}

const recursivelyDeleteKeys = (obj: { [key: string]: any }, keys: string[]) => {
    for (const [key, value] of Object.entries(obj || {})) {
        if (keys.includes(key)) {
            console.log("deleting", { [key]: obj[key] });
            delete obj[key];
        } else if (typeof value === "object") {
            recursivelyDeleteKeys(value, keys);
        }
    }
};

export const submitInterestForOffer = async ({ fullName, content, emailAddress, phoneNumber }: Props) => {
    const formData = new FormData();
    const payload = content || {};
    recursivelyDeleteKeys(payload, ["otsClauseRates", "discounts", "co2ClauseRates", "chargesProportional", "fixedRates", "chargesFixed", "chargesProportionalList", "chargesProportionalDay", "chargesProportionalNight", "chargesFixedList", "chargesFixedDay", "chargesFixedNight"]);

    formData.append(FormField.fullName, fullName);
    formData.append(FormField.phoneNumber, phoneNumber);
    formData.append(FormField.emailAddress, emailAddress);
    formData.append(FormField.json, JSON.stringify(payload));

    return fetch(submitUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
    });
};

const useStyles = createStyles((theme) => ({
    submitBtn: {
        [theme.fn.smallerThan("sm")]: {},
    },
    form: {
        minWidth: "50vw",
        [theme.fn.smallerThan("sm")]: {},
    },
    input200Per: {},
    infoCard: {
        backgroundColor: "grey",
        borderRadius: "15px",
        textAlign: "center",
        padding: "15px",
        marginBottom: "2%",
        [theme.fn.largerThan("sm")]: {
            maxWidth: "50vw",
        },
        [theme.fn.smallerThan("sm")]: {
            minWidth: "60vw",
        },
    },
}));

const InfoCard = ({ title, text }: { title: string; text: string | undefined }) => {
    const { classes } = useStyles();

    return (
        (text && (
            <div className={classes.infoCard}>
                <span style={{ fontSize: "15pt" }}>{title}</span>
                <p style={{ whiteSpace: "pre-line", textAlign: "unset" }}>{text}</p>
            </div>
        )) || <></>
    );
};

const Info = (data: { title: string; text: string | undefined }[]) => {
    return (
        data.every((text) => text != undefined) && (
            <div>
                <h3>Πληροφορίες</h3>
                {data.map((info) => (
                    <InfoCard {...info} />
                ))}
            </div>
        )
    );
};

export const InterestedForOfferForm = ({ content, setContent, closeModal, scrapedData }: { scrapedData: ApiResponse; content: Content | undefined; setContent: any; closeModal: () => void }) => {
    const { classes } = useStyles();

    const form = useForm({
        initialValues: {
            fullName: "",
            emailAddress: "",
            phoneNumber: "",
        },
        validate: {
            fullName: (value) => (value.trim().length <= 3 ? "Παρακαλώ εισάγετε το ονοματεπώνυμο σας" : null),
            emailAddress: (value) => (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ? "Σφαλμένη διεύθηνση email" : null),
            phoneNumber: (value) => (!/(\+\d{2,3})?(\d{10})/.test(value.replace(/[.-\s]/g, "")) ? "Σφαλμένος αριθμός τηλεφώνου" : null),
        },
    });

    return (
        <Center className={classes.form}>
            <form
                style={{ width: "100%", padding: "5%", paddingTop: "0px" }}
                onSubmit={form.onSubmit(
                    () => {
                        toast
                            .promise(submitInterestForOffer({ ...form.values, content }), {
                                pending: "Παρακαλώ περιμένετε καθώς αποστέλεται το αίτημα σας.",
                                success: "Επιτυχώς αποστολή του αιτήματος σας!",
                                error: "Αδυναμία αποστολής του αιτήματος σας, παρακαλώ προσπαθείστε ξανά αργότερα!",
                            })
                            .then(() => {
                                form.setValues({ fullName: "", emailAddress: "", phoneNumber: "" });
                                closeModal();
                            });
                    },
                    () => {
                        toast("Παρακαλώ συμπληρώστε σωστά όλα τα πεδία.", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            type: toast.TYPE.WARNING,
                        });
                    }
                )}
            >
                <Title>Δήλωση Ενδιαφέροντος</Title>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextInput className={classes.input200Per} label="Ονοματεπώνυμο" placeholder="Το ονοματεπώνυμο σας" {...form.getInputProps("fullName")} />
                    <TextInput className={classes.input200Per} label="Email" placeholder="Το email σας" {...form.getInputProps("emailAddress")} />
                    <TextInput className={classes.input200Per} label="Τηλεφωνικός Αριθμός" placeholder="Ο αριθμός του τηλεφώνου σας" {...form.getInputProps("phoneNumber")} />

                    <Select
                        label="Προσφορά"
                        placeholder="//"
                        defaultValue={content ? `${content.invoices.provider.name} - ${content.invoices.name}` : undefined}
                        data={scrapedData.data.content.map((e) => `${e.invoices.provider.name} - ${e.invoices.name}`)}
                        // onSelect={(e) => {
                        //     const key = (e.target as HTMLInputElement).value;
                        //     const value = (scrapedData.data.content as any[]).find((e) => `${e.invoices.provider.name} - ${e.invoices.name}` === key);

                        //     setContent(value);
                        //     console.log(value);
                        // }}
                        onChange={(key) => {
                            const value = (scrapedData.data.content as any[]).find((e) => `${e.invoices.provider.name} - ${e.invoices.name}` === key);

                            setContent(value);
                            console.log(value);
                        }}
                    />

                    {Info([
                        { title: "Περιγραφή:", text: content?.invoices?.description },
                        { title: "Εγγύηση:", text: content?.invoices?.warrantyInfo || undefined },
                        { title: "Ρήτρα Απόσυρσης:", text: content?.invoices?.withdrawmentClause !== "N/A" ? content?.invoices?.withdrawmentClause : undefined },
                    ])}

                    <Button type="submit" className={classes.submitBtn}>
                        Αποστολή
                    </Button>
                </div>
            </form>
        </Center>
    );
};
