import { provisionTypes, provisionTypesName, customersTypes } from "../types/EnumsGas"

const headers = {
    "User-Agent": navigator.userAgent.toString(),
    Accept: "application/json, text/plain, */*",
    "Accept-Language": "en-US,en;q=0.5",
    "Content-Type": "application/json",
    Referer: "https://www.energycost.gr/search-compare/el/search-compare/gas/gas/compare",
};

interface ApiRequest {
    pageIndex: number,
    pageSize: number,
    sortField: string,
    sortOrder: string,
    excludedIdsList: null,
    criteria: {
        network: Network,
        month: number,
        provisionType: { id: provisionTypes, name: provisionTypesName },
        consumption: number,
        chargeDays: number,
        defaultValues: boolean,
        load: string,
        hasWithdrawment: boolean,
        discountTypesList: number[],
        discountCustomerTypesList: customersTypes[],
    },
};

export interface ApiResponse {
    metaData: MetaData;
    data:     Data;
}

interface Data {
    content:          Content[];
    pageable:         Pageable;
    totalPages:       number;
    totalElements:    number;
    last:             boolean;
    sort:             Sort;
    numberOfElements: number;
    first:            boolean;
    size:             number;
    number:           number;
    empty:            boolean;
}

export interface Content {
    results:   Results;
    invoices:  Invoice;
    discounts: Discounts;
}

interface Discounts {
    fixedDiscounts:        FixedDiscountsClass;
    proportionalDiscounts: FixedDiscountsClass;
    otherDiscounts:        OtherDiscount[];
}

interface FixedDiscountsClass {
    constrainedDiscounts:   any[];
    unconstrainedDiscounts: UnconstrainedDiscounts;
}

interface UnconstrainedDiscounts {
    consistency: any[];
    plain:       any[];
    extra:       any[];
    sumDay:      ChargePrice;
    sumNight:    ChargePrice;
    sum:         ChargePrice;
}

type ChargePrice = string

interface OtherDiscount {
    code:            string;
    ratesList:       any[];
    provider:        Provider;
    id:              number;
    customerType:    number;
    combinationType: number;
    discountType:    number;
    chargeDays:      string;
    message:         string;
    dateFrom:        string;
    dateTo:          string;
    duration:        string;
    status:          number;
    invoices:        Invoice[];
    isExpiring:      boolean;
    revision:        null;
    application:     number;
    expiring:        boolean;
}

interface Invoice {
    id:                       number;
    code:                     string;
    name:                     string;
    description:              string;
    additionalFeatures:       string;
    warrantyInfo:             string;
    withdrawmentClause:       string;
    chargePrice:              ChargePrice;
    chargePriceConstant:      ChargePrice;
    chargeDaysProportional:   string;
    chargeDaysFixed:          string;
    provisionType:            number;
    usageCategory:            number;
    status:                   number;
    createdBy:                null;
    createdDate:              null;
    lastModifiedBy:           Email;
    lastModifiedDate:         number;
    chargesProportionalList:  Charges[];
    chargesFixedList:         Charges[];
    chargesTransportList:     ChargesTransportList[];
    hasWithdrawment:          boolean;
    provider:                 Provider;
    applicationFixed:         number;
    applicationProportional:  number;
    isReducedProportional:    boolean;
    isReducedFixed:           boolean;
    revision:                 null;
    reducedProportional:      boolean;
    reducedFixed:             boolean;
    chargesProportional:      Charges[];
    chargesProportionalDay:   Charges[];
    chargesProportionalNight: any[];
    chargesFixed:             Charges[];
    chargesFixedDay:          Charges[];
    chargesFixedNight:        any[];
}

interface Charges {
    id:               number;
    price:            string;
    kwhFrom:          string;
    kwhTo:            string;
    createdBy:        Email | null;
    createdDate:      number | null;
    lastModifiedBy:   Email;
    lastModifiedDate: number;
    isFree:           boolean;
    chargeType:       number;
    rate:             number;
    month?:           number;
    year?:            string;
}

type Email = string

interface ChargesTransportList {
    id:               number;
    price:            string;
    month:            number;
    year:             string;
    createdBy:        Email | null;
    createdDate:      number | null;
    lastModifiedBy:   Email;
    lastModifiedDate: number;
    network:          Network;
}

interface Network {
    id:   number;
    code: string;
    name: string;
}

interface Provider {
    id:     number;
    name:   string;
    typeId: number;
    status: number;
}

interface Results {
    providerCharge:          ChargePrice;
    priceNMP:                ChargePrice;
    transferPrice:           ChargePrice;
    distributionPrice:       string;
    priceFactorPerYear:      string;
    potency:                 string;
    consumption:             string;
    consumptionDays:         string;
    transferNetwork:         string;
    distributionNetwork:     string;
    comissionAmount:         string;
    proportionalAmount:      string;
    fixedAmount:             ChargePrice;
    totalCharge:             string;
    invoiceChargeDays:       ChargePrice;
    constantFixedCharge:     ChargePrice;
    totalAmount:             string;
    commissionAmount:        number;
    fixedAmountDay:          number;
    proportionalAmountDay:   number;
    proportionalAmountNight: number;
    fixedAmountNight:        number;
    isEligibleKOTA:          boolean;
    isEligibleKOTB:          boolean;
}

interface Pageable {
    sort:       Sort;
    pageNumber: number;
    pageSize:   number;
    offset:     number;
    paged:      boolean;
    unpaged:    boolean;
}

interface Sort {
    sorted:   boolean;
    unsorted: boolean;
    empty:    boolean;
}

interface MetaData {
    status:          number;
    messageCodeName: string;
    messageDev:      number;
    size:            number;
}

export const FetchPrices = async (request: ApiRequest) => {
    const res = await fetch("https://www.energycost.gr/rae-services/public/gas/compare", { method: "POST", headers, body: JSON.stringify(request) })
    return (await res.json()) as ApiResponse
}


export const Networks = [
		{
			"id": 22,
			"code": "ΑΛΕΞΑΝΔΡΟΥΠΟΛΗ",
			"name": "ΑΛΕΞΑΝΔΡΟΥΠΟΛΗ"
		},
		{
			"id": 23,
			"code": "ΔΡΑΜΑ",
			"name": "ΔΡΑΜΑ"
		},
		{
			"id": 24,
			"code": "ΘΗΒΑ",
			"name": "ΘΗΒΑ"
		},
		{
			"id": 25,
			"code": "ΚΑΒΑΛΑ",
			"name": "ΚΑΒΑΛΑ"
		},
		{
			"id": 26,
			"code": "ΚΑΤΕΡΙΝΗ",
			"name": "ΚΑΤΕΡΙΝΗ"
		},
		{
			"id": 27,
			"code": "ΚΙΛΚΙΣ",
			"name": "ΚΙΛΚΙΣ"
		},
		{
			"id": 28,
			"code": "ΚΟΜΟΤΗΝΗ",
			"name": "ΚΟΜΟΤΗΝΗ"
		},
		{
			"id": 29,
			"code": "ΛΑΜΙΑ",
			"name": "ΛΑΜΙΑ"
		},
		{
			"id": 30,
			"code": "ΞΑΝΘΗ",
			"name": "ΞΑΝΘΗ"
		},
		{
			"id": 31,
			"code": "ΟΙΝΟΦΥΤΑ",
			"name": "ΟΙΝΟΦΥΤΑ"
		},
		{
			"id": 32,
			"code": "ΠΛΑΤΥ ΗΜΑΘΙΑΣ",
			"name": "ΠΛΑΤΥ ΗΜΑΘΙΑΣ"
		},
		{
			"id": 33,
			"code": "ΡΙΤΣΩΝΑ",
			"name": "ΡΙΤΣΩΝΑ"
		},
		{
			"id": 34,
			"code": "ΣΕΡΡΕΣ",
			"name": "ΣΕΡΡΕΣ"
		},
		{
			"id": 35,
			"code": "ΣΧΗΜΑΤΑΡΙ",
			"name": "ΣΧΗΜΑΤΑΡΙ"
		},
		{
			"id": 36,
			"code": "ΧΑΛΚΙΔΑ",
			"name": "ΧΑΛΚΙΔΑ"
		},
		{
			"id": 1,
			"code": "Αττική",
			"name": "ΑΤΤΙΚΗ"
		},
		{
			"id": 2,
			"code": "Θεσσαλονίκη",
			"name": "ΘΕΣΣΑΛΟΝΙΚΗ"
		},
		{
			"id": 3,
			"code": "Θεσσαλία",
			"name": "ΘΕΣΣΑΛΙΑ"
		}
	]