import { Center, createStyles, Accordion, Image } from "@mantine/core";
import { Helmet } from "react-helmet";

const useStyles = createStyles((theme) => ({
    about: {
        width: "50vw",
        [theme.fn.smallerThan("sm")]: {
            "&": {
                width: "90vw",
            },
        },
    },
    providers: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        [theme.fn.smallerThan("sm")]: {
            "&": {
                gridTemplateColumns: "auto auto auto",
            },
        },
    },
}));

export function About() {
    const { classes } = useStyles();
    return (
        <Center>
            <Helmet>
                <title>Ενέργεια - Σχετικά</title>
            </Helmet>
            <Accordion className={classes.about}>
                <Accordion.Item value="asd">
                    <Accordion.Control>
                        <h4>Τι ισχύει βάση της τροπολογίας του νόμου του Υπουργείου Περιβάλλοντος και Ενέργειας</h4>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <p>
                            Με τροπολογία που κατατέθηκε στο σχέδιο νόμου του Υπουργείου Περιβάλλοντος και Ενέργειας <br />
                            για τον εκσυγχρονισμό της αδειοδοτικής διαδικασίας έργων
                            <br />
                            ΑΠΕ, προβλέπεται η κατάργηση της ρήτρας αναπροσαρμογής σε κυμαινόμενα τιμολόγια <br />
                            προμήθειας ηλεκτρικής ενέργειας για 11 μήνες, από την 1η Αυγούστου <br />
                            2022 έως την 1η Ιουλίου 2023.
                            <br />• <b>Η νομοθετική ρύθμιση προβλέπει τα εξής</b>:<br />
                            <br />
                            • Έως τις 10 Ιουλίου οι εταιρείες προμήθειας ηλεκτρικής ενέργειας θα πρέπει να αναρτήσουν στην ιστοσελίδα τους τις τιμές που <br />
                            θα ισχύσουν από την 1η Αυγούστου. Οι λογαριασμοί που θα εκδοθούν τον Αύγουστο δεν θα περιλαμβάνουν ρήτρα αναπροσαρμογής.
                            <br />
                            • Δίνεται η δυνατότητα στους καταναλωτές να αλλάζουν πάροχο αζημίως, <br />
                            δίχως να χρειάζεται να καταβάλλουν αποζημίωση (penalty) λόγω πρόωρης αποχώρησης.
                            <br />
                            • Για την καλύτερη ενημέρωση των καταναλωτών και την ενίσχυση της διαφάνειας, <br />
                            οι εταιρείες προμήθειας ηλεκτρικής ενέργειας υποχρεώνονται να <br />
                            ανακοινώνουν κάθε μήνα σε ευδιάκριτο σημείο στην ιστοσελίδα τους τις χρεώσεις <br />
                            προμήθειας ηλεκτρισμού που θα ισχύουν στα τιμολόγιά τους για τον επόμενο μήνα.
                            <br />
                            • Με τον τρόπο αυτόν οι καταναλωτές θα έχουν επαρκές χρονικό διάστημα στη διάθεσή τους προκειμένου να αναζητήσουν φθηνότερα τιμολόγια και να <br /> αλλάξουν πάροχο εφόσον το επιθυμούν.
                            <br />
                            • Οι εταιρείες προμήθειας ηλεκτρικής ενέργειας υποχρεώνονται να αναγράφουν στους έντυπους <br />
                            και τους ηλεκτρονικούς λογαριασμούς, σε ευδιάκριτο σημείο δίπλα στο πληρωτέο ποσό, το ύψος της επιδότησης από το Ταμείο Ενεργειακής Μετάβασης (ΤΕΜ).
                            <br />
                            <br />
                            Στο ίδιο σχέδιο νόμου περιλαμβάνεται και η θεσμική παρέμβαση στη χονδρεμπορική αγορά ηλεκτρικής ενέργειας, <br />
                            με τη σύσταση μηχανισμού ανάκτησης των υπέρ εσόδων των ηλεκτροπαραγωγών. <br />Ο μηχανισμός εφαρμόζεται από τον Ιούλιο 2022 έως την 1η Ιουνίου 2023
                            <br /> και αποσυνδέει τις υψηλές τιμές στη χονδρική αγορά φυσικού αερίου από τις τιμές ηλεκτρικής ενέργειας:
                            <br />
                            <br />
                            <b>Ειδικότερα:</b>
                            <br />
                            <br />
                            • Ορίζονται ανώτατα αποζημίωσης για τις μονάδες παραγωγής ηλεκτρικής ενέργειας, <br />
                            βάσει του πραγματικού τους κόστους λειτουργίας.
                            <br />
                            <br />
                            • Η διαφορά μεταξύ της χρηματιστηριακής τιμής και της αποζημίωσης που θα οριστεί <br />
                            με απόφαση του Υπουργού Περιβάλλοντος και Ενέργειας, <br />
                            θα δεσμεύεται υπέρ του ΤΕΜ και θα χρησιμοποιείται για την επιδότηση των <br />
                            οικιακών και επαγγελματικών λογαριασμών ηλεκτρικής ενέργειας και φυσικού αερίου.
                            <br />
                            <br />
                            Από την 1η Ιουλίου με τη νέα θεσμική παρέμβαση στην αγορά ηλεκτρικής ενέργειας απορροφάται:
                            <br />
                            • Στους ευάλωτους καταναλωτές που δικαιούνται Κοινωνικό Οικιακό Τιμολόγιο (περίπου 500.000 νοικοκυριά) το 100% της αύξησης.
                            <br />
                            • Σε όλα τα οικιακά τιμολόγια έως και το 85% της αύξησης.
                            <br />
                            • Σε 1,25 εκατ. μικρομεσαίες επιχειρήσεις το 82% της αύξησης.
                            <br />• Στους αγρότες και τα αγροτικά τιμολόγια έως το 90% της αύξησης.
                        </p>
                        <p style={{ fontSize: "0.5rem" }}>
                            Πηγή: <a href="https://gr.euronews.com/2022/06/25/ellada-katargisi-ritras-anaprosarmogis-ti-simainei-gia-tous-logariasmous-reumatos">gr.euronews.com</a>
                        </p>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="1">
                    <Accordion.Control>
                        <h4>Ποια τιμολόγια μπορώ να βρω στο Εργαλείο Σύγκρισης Τιμών?</h4>
                    </Accordion.Control>
                    <Accordion.Panel>
                        Πεδίο εφαρμογής του Εργαλείου είναι τα τιμολόγια Μικρών Πελατών Χαμηλής Τάσης ηλεκτρικής ενέργειας και τα Οικιακά και Εμπορικά τιμολόγια Τελικών Πελατών Φυσικού Αερίου. Για την περίπτωση πελατών Ηλεκτρικής Ενέργειας, Μικροί Πελάτες θεωρούνται οι Οικιακοί πελάτες, καθώς και οι Επαγγελματικοί πελάτες με παροχές που δεν ξεπερνούν τα 25 kVA σε εγκατεστημένη ισχύ. Οι μεγάλες
                        επαγγελματικές παροχές Χαμηλής Τάσης (τα γνωστά Γ22 τιμολόγια), δεν θεωρούνται μικροί πελάτες.
                        <p>
                            Όλες οι πληροφορίες σχετικά με τους τιμοκατάλογους των χρεώσεων του κόστους ενέργειας προέρχονται απο την ΡΑΕ.
                            <br />
                            Δείτε περισσότερα <a href="https://energycost.gr">εδώ</a>.
                        </p>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="providers">
                    <Accordion.Control>
                        <h4>Ποιοι είναι οι ενεργοί Προμηθευτές και ποιες οι ιστοσελίδες τους;</h4>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <div className={classes.providers}>
                            <a href="https://www.dei.gr" target="_blank" rel="noreferrer">
                                <Image style={{ background: "white" }} alt="DEI" src="https://www.dei.gr/media/nmrbwpxw/dei-header.svg" width={120} height={73} />
                            </a>
                            <a href="https://www.depa.gr" target="_blank" rel="noreferrer">
                                <Image alt="DEPA" src="https://www.energycost.gr/sites/default/files/pictures/depa.png" width={110} height={73} />
                            </a>
                            <a href="https://energy.elin.gr/" target="_blank" rel="noreferrer">
                                <Image alt="ELIN" src="https://www.energycost.gr/sites/default/files/pictures/elin.png" width={110} height={73} />
                            </a>
                            <a href="https://eltaenergeia.gr" target="_blank" rel="noreferrer">
                                <Image alt="ELTA ENERGY" src="https://www.energycost.gr/sites/default/files/pictures/elta.png" width={108} height={72} />
                            </a>
                            <a href="https://www.zenith.gr/" target="_blank" rel="noreferrer">
                                <Image alt="ZENITH" src="https://www.energycost.gr/sites/default/files/pictures/zenith.png" width={109} height={73} />
                            </a>
                            <a href="https://www.heron.gr/" target="_blank" rel="noreferrer">
                                <Image alt="IRON" src="https://www.energycost.gr/sites/default/files/pictures/iron.png" width={109} height={73} />
                            </a>
                            <a href="https://www.k-en.gr/" target="_blank" rel="noreferrer">
                                <Image alt="KEN" src="https://www.energycost.gr/sites/default/files/pictures/ken.png" width={121} height={81} />
                            </a>
                            <a href="https://ote-estate.gr/el-gr" target="_blank" rel="noreferrer">
                                <Image alt="OTE ESTATE" src="https://www.energycost.gr/sites/default/files/pictures/ote.png" width={128} height={76} />
                            </a>
                            <a href="https://www.fysikoaerioellados.gr/" target="_blank" rel="noreferrer">
                                <Image alt="FUSIKO AERIO" src="https://www.energycost.gr/sites/default/files/pictures/fusikoAerio.png" width={114} height={76} />
                            </a>
                            <a href="https://efaenergy.gr/" target="_blank" rel="noreferrer">
                                <Image alt="EFA" src="https://www.energycost.gr/sites/default/files/pictures/efa.png" width={120} height={80} />
                            </a>
                            <a href="https://www.elpedison.gr/" target="_blank" rel="noreferrer">
                                <Image alt="ELPEDISON" src="https://www.energycost.gr/sites/default/files/pictures/elpedison.png" width={120} height={80} />
                            </a>
                            <a href="https://www.nrg.gr/" target="_blank" rel="noreferrer">
                                <Image alt="NRG" src="https://www.energycost.gr/sites/default/files/pictures/nrg.png" width={206} height={71} />
                            </a>
                            <a href="https://www.petrogaz.gr/" target="_blank" rel="noreferrer">
                                <Image alt="PETROGAZ" src="https://www.energycost.gr/sites/default/files/pictures/petrogaz.png" width={124} height={81} />
                            </a>
                            <a href="https://www.protergia.gr/" target="_blank" rel="noreferrer">
                                <Image alt="PROTERGIA" src="https://www.energycost.gr/sites/default/files/pictures/protergia.png" width={125} height={79} />
                            </a>
                            <a href="https://www.senergy.gr/" target="_blank" rel="noreferrer">
                                <Image alt="SOLAR ENERGY" src="https://www.energycost.gr/sites/default/files/pictures/solarEnergy.png" width={120} height={86} />
                            </a>
                            <a href="http://www.viener.eu/" target="_blank" rel="noreferrer">
                                <Image alt="VIENER" src="https://www.energycost.gr/sites/default/files/pictures/viener.png" width={120} height={80} />
                            </a>
                            <a href="https://www.volterra.gr/" target="_blank" rel="noreferrer">
                                <Image alt="VOLTERRA" src="https://www.energycost.gr/sites/default/files/pictures/volterra.png" width={120} height={80} />
                            </a>
                            <a href="https://volton.gr/" target="_blank" rel="noreferrer">
                                <Image alt="VOLTON" src="https://www.energycost.gr/sites/default/files/pictures/volton.png" width={120} height={80} />
                            </a>
                            <a href="https://www.watt-volt.gr/" target="_blank" rel="noreferrer">
                                <Image alt="WATT+VOLT" src="https://www.energycost.gr/sites/default/files/pictures/watt+volt.png" width={120} height={80} />
                            </a>
                            <a href="https://weenergy.gr/" target="_blank" rel="noreferrer">
                                <Image alt="WE ENERGY" src="https://www.energycost.gr/sites/default/files/pictures/weEnergy.png" width={94} height={94} />
                            </a>
                        </div>{" "}
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Center>
    );
}
