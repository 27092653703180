export enum provisionTypes {
    CENTRAL = 0,
    AUTONOMUS = 1,
    PROFESSIONAL = 2,
}

export enum provisionTypesName {
    CENTRAL = "CENTRAL",
    AUTONOMUS = "AUTONOMUS",
    PROFESSIONAL = "PROFESSIONAL",
}

export enum usageCategories {
    HEATING = 0,
    COOKING = 1,
    HOT_WATER = 2,
    ALL = 3,
}

export enum usageCategoriesName {
    HEATING = "HEATING",
    COOKING = "COOKING",
    HOT_WATER = "HOT_WATER",
    ALL = "ALL",
}

export enum scaleApplications {
    EXCESSIVE = 0,
    TOTAL = 1,
}

export enum scaleApplicationsName {
    EXCESSIVE = "EXCESSIVE",
    TOTAL = "TOTAL",
}

export enum customersTypes {
    ALL = 0,
    NEW = 1,
    EXISTING = 2,
}

export enum customersTypesName {
    ALL = "ALL",
    NEW = "NEW",
    EXISTING = "EXISTING",
}
