import type { Request } from "../types/ApiRequestElectricity"

const headers = {
    "User-Agent": navigator.userAgent.toString(),
    "Accept": "application/json, text/plain, */*",
    "Accept-Language": "en-US,en;q=0.5",
    "Content-Type": "application/json",
    "Referer": "https://www.energycost.gr/search-compare/el/search-compare/electricity/electricity/compare"
}

const compareLink = "https://www.energycost.gr/rae-services/public/electricity/compare"

export const FetchPrices = async (request: Request) => {
    const res = await fetch(compareLink, { method: "POST", headers, body: JSON.stringify(request) })
    return (await res.json()) as ApiResponse
}

export const getAllLogos = async () => {
    const res = (await (await fetch("https://www.energycost.gr/rae-services/public/provider/icons")).json())?.data || {}
    const temp: {[id: number]: string} = {}

    for (const logo of res) {
        temp[logo.provider.id] = logo.icons
    }

    return temp

}

export interface ApiResponse {
    metaData: MetaData;
    data:     Data;
}

export interface Data {
    content:          Content[];
    pageable:         Pageable;
    totalPages:       number;
    totalElements:    number;
    last:             boolean;
    sort:             Sort;
    numberOfElements: number;
    first:            boolean;
    size:             number;
    number:           number;
    empty:            boolean;
}

export interface Content {
    results:   Results;
    invoices:  Invoice;
    discounts: Discounts;
}

export interface Discounts {
    fixedDiscounts:        FixedDiscountsClass;
    proportionalDiscounts: FixedDiscountsClass;
    otherDiscounts:        OtherDiscount[];
}

export interface FixedDiscountsClass {
    constrainedDiscounts:   ConstrainedDiscount[];
    unconstrainedDiscounts: UnconstrainedDiscounts;
}

export interface ConstrainedDiscount {
    discounts:            OtherDiscount;
    unDiscountedValue:    string;
    discountedValueDay:   string;
    discountedValueNight: string;
    consumption:          string;
}

export interface OtherDiscount {
    id:              number;
    code:            string;
    customerType:    number;
    combinationType: number;
    discountType:    number;
    application:     number;
    message:         string;
    dateFrom:        Date;
    dateTo:          Date;
    duration:        string;
    status:          number;
    chargeDays:      string;
    invoices:        Invoice[];
    provider:        Provider;
    isExpiring:      boolean;
    ratesList:       RatesList[];
    revision:        null;
    expiring:        boolean;
}

export type email = string
export interface Invoice {
    id:                       number;
    code:                     string;
    name:                     string;
    description:              string;
    additionalFeatures:       null | string;
    warrantyInfo:             null | string;
    withdrawmentClause:       string;
    otsClause:                null;
    co2Clause:                null;
    minCharge1f:              string;
    minCharge3f:              string;
    category:                 number;
    status:                   number;
    createdBy:                email | null;
    createdDate:              number | null;
    lastModifiedBy:           email;
    lastModifiedDate:         number;
    type:                     Type;
    kot:                      Kot;
    provider:                 Provider;
    chargesProportionalList:  ChargesProportional[];
    chargesProportionalDay:   ChargesProportional[];
    chargesProportionalNight: ChargesProportional[];
    chargesFixedList:         ChargesFixed[];
    chargesFixedDay:          ChargesFixed[];
    chargesFixedNight:        ChargesFixed[];
    hasOTS:                   boolean;
    hasCo2:                   boolean;
    hasWithdrawment:          boolean;
    revision:                 null;
    chargeDaysProportional:   string;
    chargeDaysFixed:          string;
    applicationFixed:         number;
    applicationProportional:  number;
    isReducedProportional:    boolean;
    isReducedFixed:           boolean;
    otsClauseRates:           OtsClauseRate[];
    co2ClauseRates:           any[];
    otsIsComplied:            boolean | null;
    co2IsComplied:            boolean | null;
    chargesProportional:      ChargesProportional[];
    fixedRates:               ChargesFixed[];
    chargesFixed:             ChargesFixed[];
}

export interface ChargesFixed {
    id:         number;
    chargeType: number;
    isFree:     boolean;
    price_1f:   string;
    price_3f:   string;
    kwhFrom:    string;
    kwhTo:      string;
    free:       boolean;
    rate:       number;
}

export interface ChargesProportional {
    id:          number;
    chargeType:  number;
    energyPrice: string;
    powerPrice:  string;
    kwhFrom:     string;
    kwhTo:       string;
    isFree:      boolean;
    rate:        number;
}

export interface Kot {
    id:   number;
    name: KotName;
}

export enum KotName {
    No = "Όχι",
    KotaKotb = "ΚΟΤΑ/ΚΟΤΒ",
}

export interface OtsClauseRate {
    id:               number;
    kwhFrom:          string;
    kwhTo:            string;
    addedFactor:      string;
    multipliedFactor: string;
    chargeType:       number;
}

export interface Provider {
    id:     number;
    name:   string;
    typeId: number;
    status: number;
}

export interface Type {
    id:             number;
    name:           TypeName;
    description:    null;
    isProfessional: boolean;
    isNight:        boolean;
}

export enum TypeName {
    ProffesionalSmall = "Επαγγελματικό Μικρή",
    ProffesionalNight = "Επαγγελματικό Νυχτερινό",
    HomeAllday = "Οικιακό Ημερήσιο",
    HomeNight = "Οικιακό Νυχτερινό",
}

export interface RatesList {
    id:         number;
    rate:       string;
    kwhFrom:    string;
    kwhTo:      string;
    chargeType: number;
    isFree:     boolean;
}

export interface UnconstrainedDiscounts {
    consistency: ConstrainedDiscount[];
    plain:       ConstrainedDiscount[];
    extra:       any[];
    sumDay:      string;
    sumNight:    string;
    sum:         string;
}

export interface Results {
    transportAmount:         string;
    distributionAmount:      string;
    otherAmount:             string;
    etmearAmount:            string;
    ykoAmount:               string;
    fixedAmount:             string;
    fixedAmountDay:          string;
    fixedAmountNight:        string;
    proportionalAmount:      string;
    proportionalAmountDay:   string;
    proportionalAmountNight: string;
    commissionAmount:        string;
    totalAmount:             string;
    adjustableAmount:        string;
    otsClauseDay:            string;
    co2ClauseDay:            string;
    otsClauseNight:          string;
    co2ClauseNight:          string;
    co2TotalClause:          string;
    otsTotalClause:          string;
    provider:                Provider;
    description:             null;
    eligibleKot:             boolean;
    eligibleKotA:            boolean;
    eligibleKotB:            boolean;
    isEligibleKOTA:          boolean;
    isEligibleKOTB:          boolean;
    name:                    string;
}

export interface Pageable {
    sort:       Sort;
    pageNumber: number;
    pageSize:   number;
    offset:     number;
    paged:      boolean;
    unpaged:    boolean;
}

export interface Sort {
    sorted:   boolean;
    unsorted: boolean;
    empty:    boolean;
}

export interface MetaData {
    status:          number;
    messageCodeName: string;
    messageDev:      number;
    size:            number;
}
