/* eslint-disable jsx-a11y/iframe-has-title */
import { Checkbox, TextInput, createStyles, Textarea, SimpleGrid, Group, Title, Button, Center, MediaQuery } from "@mantine/core";
import { toast } from "react-toastify";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { subToNewsletter } from "./newsletter";
import { adData, rotatingBanners } from "../App";

const useStyles = createStyles((theme) => ({
    form: {
        width: "50%",
        [theme.fn.smallerThan("sm")]: {
            "&": {
                width: "90%",
            },
        },
    },
    consent: {
        display: "flex",
        flexDirection: "row",
        [theme.fn.smallerThan("sm")]: {
            "&": {
                alignItems: "center",
                alignContent: "center",
            },
            '& > [class*="Checkbox"]': {
                marginRight: "2%",
            },
        },
    },
    required: {
        "&::before": {
            content: `"*"`,
        },
    },
    submitBtn: {
        [theme.fn.smallerThan("sm")]: {
            marginBottom: "10%",
        },
    },
}));

function shuffle(array: any[]) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}
export function Contact() {
    const { classes } = useStyles();
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const submitUrl = "https://docs.google.com/forms/d/e/1FAIpQLSdrcoUifOqlJtKJHh5_FCCeYQamf1ctAJJBRw8s5DglpgNckg/formResponse";
    const formFieldKeys = {
        fullname: "entry.1719160999",
        email: "entry.46017317",
        subject: "entry.832929076",
        message: "entry.131260926",
    };
    const form = useForm({
        initialValues: {
            fullname: "",
            email: "",
            subject: "",
            message: "",
            subscribeToNewsletter: false,
        },
        validate: {
            fullname: (value) => {
                const words = value
                    .trim()
                    .split(/\s+/)
                    .sort((a, b) => a.length - b.length)
                    .reverse();
                if (words.length === 0) return "Παρακαλώ εισάγετε το ονοματεπώνυμο σας.";
                if (words.length === 1) return "Παρακαλώ εισάγετε ολόκληρο το ονοματεπώνυμο σας.";
                if (words.length >= 2 && (words[0].length <= 2 || words[1].length <= 2)) return "Τουλάχιστων δύο λέξεις πρέπει να έχουν παραπάνω απο 2 γράμματα.";
                return false;
            },
            email: (value) => (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ? "Σφαλμένη διεύθηνση email" : null),
            subject: (value) => (value.trim().length === 0 ? "Παρακαλώ εισάγετε ένα θέμα" : null),
        },
        validateInputOnChange: true,
    });

    const [mobileAdBanners, setMobileAdBanners] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const bannerIds = adData.mobileHorizontal.bannerIds.filter((banner) => banner.expiresAt === undefined || Date.now() < (banner.expiresAt as Date).getTime());
        shuffle(bannerIds);

        setMobileAdBanners(() =>
            [rotatingBanners.mobileCenter, rotatingBanners.mobileCenter].map((b, i) => {
                return <iframe style={{ zIndex: "6", width: `468px`, height: `90px`, transform: "scale(0.8)" }} src={b!!.baseLink.replace("{ID}", bannerIds[i].bannerId)} scrolling="no" frameBorder="0"></iframe>;
            })
        );
    }, []);

    return (
        <Center style={{ marginBottom: "1%" }}>
            <form
                onSubmit={form.onSubmit(
                    () => {
                        const formData = new FormData();
                        // eslint-disable-next-line no-lone-blocks
                        {
                            formData.append(formFieldKeys.fullname, form.values.fullname);
                            formData.append(formFieldKeys.email, form.values.email);
                            formData.append(formFieldKeys.subject, form.values.subject);
                            formData.append(formFieldKeys.message, form.values.message);
                        }

                        toast
                            .promise(
                                fetch(submitUrl, {
                                    method: "POST",
                                    mode: "no-cors",
                                    body: formData,
                                }),
                                {
                                    pending: "Παρακαλώ περιμένετε καθώς προσπαθούμε να στείλουμε το αίτημα σας.",
                                    success: "Επιτυχής αποστολή του αιτήματος σας!",
                                    error: "Αδυναμία αποστολής του αιτήματος, παρακαλώ προσπαθείστε ξανά αργότερα!",
                                }
                            )
                            .then(() => form.setValues({ fullname: "", email: "", subject: "", message: "", subscribeToNewsletter: false }))
                            .then(() => {
                                if (form.values.subscribeToNewsletter) {
                                    return subToNewsletter(form.values.email);
                                }
                            });
                    },
                    () => {
                        toast("Παρακαλώ συμπληρώστε σωστά όλα τα πεδία.", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            type: toast.TYPE.WARNING,
                        });
                    }
                )}
                className={classes.form}
            >
                <Title order={2} size="h1" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: "initial" })} weight={900} align="center">
                    Επικοινωνία
                </Title>

                <SimpleGrid cols={2} mt="xl" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
                    <TextInput label="Ονοματεπώνυμο" placeholder="Το οναματεπώνυμο σας" name="fullname" {...form.getInputProps("fullname")} />
                    <TextInput label="Email" placeholder="Το email σας" name="email" {...form.getInputProps("email")} />
                </SimpleGrid>

                <TextInput label="Θέμα" mt="md" name="subject" placeholder="Το θέμα σας" {...form.getInputProps("subject")} />
                <Textarea mt="md" label="Μήνυμα" placeholder="Το μήνυμα σας" maxRows={10} minRows={5} autosize name="message" {...form.getInputProps("message")} />

                <Group style={{ display: "flex", flexDirection: "column" }} position="center" mt="xl">
                    <span className={classes.consent} style={{ fontSize: "10pt" }}>
                        <Checkbox
                            onInput={(e) => {
                                setSubmitDisabled(!((e.nativeEvent.target as any).checked || false));
                            }}
                        />
                        <span>
                            &nbsp;&nbsp;Συμφωνώ με τους &nbsp;&nbsp;
                            <a href="/legal" target="_blank">
                                Όρους χρήσης και την Πολιτική του απορρήτου
                            </a>
                            &nbsp;&nbsp;
                        </span>
                    </span>
                    <span className={classes.consent} style={{ fontSize: "10pt", marginBottom: "15%" }}>
                        <Checkbox {...form.getInputProps("subscribeToNewsletter")} />
                        <span>
                            &nbsp;&nbsp;<sup>(Προαιρετικό)</sup> Επιθυμώ να λαμβάνω ενημερώσεις, προσφορές και συμβουλές από το psonistiri.gr στο email μου.&nbsp;&nbsp;
                        </span>
                    </span>

                    <MediaQuery query="(min-width: 668px)" styles={{ display: "none" }}>
                        <div style={{ position: "absolute", left: "calc(50% - calc(468px / 2))", marginTop: "15%" }}>{mobileAdBanners?.[0]}</div>
                    </MediaQuery>

                    <Button className={classes.submitBtn} disabled={submitDisabled} size="md" type="submit">
                        Αποστολή
                    </Button>
                </Group>

                <MediaQuery query="(min-width: 668px)" styles={{ display: "none" }}>
                    <div style={{ position: "absolute", left: "calc(50% - calc(468px / 2))" }}>{mobileAdBanners?.[1]}</div>
                </MediaQuery>
            </form>
        </Center>
    );
}
