import { Center, Box } from "@mantine/core"
import { ReactElement } from "react";
import { AiOutlineWarning } from "react-icons/ai"


interface WarningProps {
    msg: string;
    children?: (ReactElement | string)[];
}

export const Warning = (props: WarningProps) => {
    return <Center>
        <Box style={{ display: "flex", flexDirection: "row", border: "2px solid green", borderRadius: "50px", padding: "2rem", marginBottom: "2rem", maxWidth: "70%" }}>
            <AiOutlineWarning fill="green" size={60 + (((props.msg.length / 50) / 2) * 15)} style={{ marginRight: "1rem" }} />
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>{props.msg}</div>
            {props.children && props.children}
        </Box>
    </Center>
}