import { TextInput, createStyles, SimpleGrid, Title, Button, Center } from "@mantine/core";
import { useForm } from "@mantine/form";
import { toast } from "react-toastify";

enum FormIds {
    email = "entry.1913279333",
}

const submitUrl = "https://docs.google.com/forms/d/e/1FAIpQLSdP4rGYhedNy_jcwd5ILj1z2boXrdwggdeDU1c-pACF-w9l_Q/formResponse";

interface props {
    path: string;
}

const useStyles = createStyles((theme) => ({
    submitBtn: {
        marginLeft: "37%",
        paddingLeft: "2%",
        [theme.fn.smallerThan("sm")]: {
            "&": {
                marginLeft: "31%",
            },
        },
    },
    newsletterForm: {
        [theme.fn.smallerThan("sm")]: {
            "&": {
                marginTop: "20%",
            },
        },
    },
    input200Per: {
        "& input": {
            width: "200%",
        },
    },
}));

export const subToNewsletter = async (email: string) => {
    const formData = new FormData();
    formData.append(FormIds.email, email);

    return fetch(submitUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
    });
};

export const NewsLetterForm = ({ path }: props) => {
    const { classes } = useStyles();

    const form = useForm({
        initialValues: {
            email: "",
        },
        validate: {
            email: (value) => (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ? "Σφαλμένη διεύθηνση email" : null),
        },
    });

    return (
        <Center className={classes.newsletterForm}>
            <form
                style={path.includes("contact") ? { marginTop: "5%" } : {}}
                onSubmit={form.onSubmit(
                    () => {
                        toast
                            .promise(subToNewsletter(form.values.email), {
                                pending: "Παρακαλώ περιμένετε, η εγγραφή είναι σε εξέληξη.",
                                success: "Επιτυγχής εγγραφή στο NewsLetter μας!",
                                error: "Αδυναμία εγγραφής, παρακαλώ προσπαθείστε ξανά αργότερα!",
                            })
                            .then(() => form.setValues({ email: "" }));
                    },
                    () => {
                        toast("Παρακαλώ εισάγετε μια αληθηνή διεύθηνση email.", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            type: toast.TYPE.WARNING,
                        });
                    }
                )}
            >
                <SimpleGrid>
                    <div style={{ marginLeft: "2%" }}>
                        <Title style={{ fontSize: "0.9em" }} size="md">
                            Εγγραφή στο Newsletter
                        </Title>
                        <span style={{ fontSize: "10pt", width: "80%" }}>Εάν θέλετε να μείνετε ενημερωμένοι με το psonistiri.gr, κάνετε εγγραφή στο newsletter μας!</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <TextInput className={classes.input200Per} style={{ margin: "10px", marginTop: "0", marginRight: "0" }} placeholder="Το email σας" {...form.getInputProps("email")} />
                        <Button type="submit" className={classes.submitBtn}>
                            Εγγραφή Τώρα
                        </Button>
                    </div>
                </SimpleGrid>
            </form>
        </Center>
    );
};
