import { useState } from "react";
import { createStyles, Header, Group, Container, Burger, Box, Transition, ActionIcon, MediaQuery } from "@mantine/core";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { Link, useNavigate } from "react-router-dom";
import { IconBrandFacebook, IconBrandInstagram } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
    inner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 56,
    },

    sideMenu: {
        [theme.fn.largerThan("sm")]: {
            visibility: "hidden",
        },
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[0],
        position: "absolute",
        zIndex: 800,
        transform: "translateX(-11%) translateY(8%)",
        width: "400%",
        "a,span": {
            zIndex: 900,
            padding: "10%",
        },
    },

    links: {
        width: 300,

        [theme.fn.smallerThan("sm")]: {
            display: "none",
        },
    },

    social: {
        width: 260,

        [theme.fn.smallerThan("sm")]: {
            width: "auto",
            marginLeft: "auto",
        },
    },

    burger: {
        marginRight: theme.spacing.md,

        [theme.fn.largerThan("sm")]: {
            display: "none",
        },
    },

    link: {
        display: "block",
        lineHeight: 1,
        padding: "8px 12px",
        borderRadius: theme.radius.sm,
        textDecoration: "none",
        color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        "&:hover": {
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    subLink: {
        "&:hover": {
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    linkActive: {
        "&, &:hover": {
            backgroundColor: theme.colors.orange,
            color: "white",
        },
    },
    fb: {
        "&:hover, &:focus, &:active": {
            backgroundColor: "rgb(62, 70, 191)",
            color: "white",
        },
    },
}));

interface SingleLink {
    label: string;
    link: string;
}

export function HeaderMiddle() {
    const [opened, { toggle, close }] = useDisclosure(false);
    const path = window.location.pathname;
    const isActive = (link: SingleLink) => {
        const uri = link.link;
        if (uri.startsWith("/") && uri === path) return true;
        else if (path.endsWith(uri)) return true;
    };

    const links = [{ link: "/" }, { link: "/about" }, { link: "/contact" }] as any as SingleLink[];

    const [active, setActive] = useState(
        links.filter((link) => {
            if (isActive(link)) return true;
            return false;
        })[0]?.link || links[0].link
    );

    const { classes, cx } = useStyles();
    const navigate = useNavigate();
    const clickOutsideRef = useClickOutside(close);

    const getAboutUri = () => {
        let part: string | void = window.location.pathname.replace(/^\//, "").split("/")[0];
        if (["contact", "about", "legal"].includes(part)) part = undefined;
        return part === undefined ? "about" : `${part}/about`;
    };

    return (
        <Header height={56} mb={120} style={{ zIndex: 2 }}>
            <Container className={classes.inner}>
                <div ref={clickOutsideRef}>
                    <Burger opened={opened} onClick={toggle} size="sm" className={classes.burger} />

                    <Transition transition="slide-right" mounted={opened} timingFunction="ease-in-out" duration={300}>
                        {(styles) => (
                            <div style={styles}>
                                <Box className={classes.sideMenu}>
                                    <Link
                                        className={cx(classes.link, { [classes.linkActive]: active === "/" })}
                                        key="Αρχική"
                                        to="/"
                                        onClick={() => {
                                            setActive("/");
                                            close();
                                        }}
                                    >
                                        Αρχική
                                    </Link>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        className={cx(classes.link, { [classes.linkActive]: active.includes("about") })}
                                        onClick={() => {
                                            const uri = getAboutUri();
                                            setActive(uri);
                                            navigate(uri);
                                            close();
                                        }}
                                    >
                                        Σχετικά
                                    </span>
                                    <Link
                                        className={cx(classes.link, { [classes.linkActive]: active === "/contact" })}
                                        key="Επικοινωνία"
                                        to="/contact"
                                        onClick={() => {
                                            setActive("/contact");
                                            close();
                                        }}
                                    >
                                        Επικοινωνία
                                    </Link>
                                </Box>
                            </div>
                        )}
                    </Transition>
                </div>
                <Group className={classes.links} spacing={5}>
                    <Link className={cx(classes.link, { [classes.linkActive]: active === "/" })} key="Αρχική" to="/" onClick={() => setActive("/")}>
                        Αρχική
                    </Link>
                    <span
                        style={{ cursor: "pointer" }}
                        className={cx(classes.link, { [classes.linkActive]: active.includes("about") })}
                        onClick={() => {
                            const uri = getAboutUri();
                            setActive(uri);
                            navigate(uri);
                        }}
                    >
                        Σχετικά
                    </span>
                    <Link className={cx(classes.link, { [classes.linkActive]: active === "/contact" })} key="Επικοινωνία" to="/contact" onClick={() => setActive("/contact")}>
                        Επικοινωνία
                    </Link>
                </Group>
                <MediaQuery styles={{ display: "none" }} largerThan="sm">
                    <div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <ActionIcon size="lg" className={classes.fb} onClick={() => window.open("https://www.facebook.com/profile.php?id=100086634256132", "_blank")}>
                                <IconBrandFacebook size={18} stroke={1.5} fill="white" />
                            </ActionIcon>
                            <ActionIcon size="lg">
                                <IconBrandInstagram size={18} stroke={1.5} fill="white" onClick={() => window.open("https://www.instagram.com/psonistiri.gr/", "_blank")} />
                            </ActionIcon>
                        </div>
                    </div>
                </MediaQuery>
            </Container>
        </Header>
    );
}
