export enum invoicesTypes {
    Οικιακό_Ημερήσιο=1,
    Οικιακό_Νυχτερινό=2,
    Επαγγελματικό_Μικρή=5,
    Επαγγελματικό_Νυχτερινό=6,
}

export enum invoicesTypesName {
    Οικιακό_Ημερήσιο="Οικιακό Ημερήσιο",
    Οικιακό_Νυχτερινό="Οικιακό Νυχτερινό",
    Επαγγελματικό_Μικρή="Επαγγελματικό Μικρή",
    Επαγγελματικό_Νυχτερινό="Επαγγελματικό Νυχτερινό",
}

export enum invoicesKot {
    Όχι=1,
    ΚΟΤΑ_and_ΚΟΤΒ=2,
    ΚΟΤΑ=3,
    ΚΟΤΒ=4,
}

export enum invoicesKotName {
    Όχι="Όχι",
    ΚΟΤΑ_and_ΚΟΤΒ="ΚΟΤΑ/ΚΟΤΒ",
    ΚΟΤΑ="ΚΟΤΑ",
    ΚΟΤΒ="ΚΟΤΒ",
}

export enum invoicesCategories {
    HOME=0,
    PROFESSIONAL=1,
}

export enum invoicesCategoriesName {
    HOME="HOME",
    PROFESSIONAL="PROFESSIONAL",
}

export enum chargesTypes {
    DAY=0,
    NIGHT=1,
}

export enum chargesTypesName {
    DAY="DAY",
    NIGHT="NIGHT",
}

export enum scaleApplications {
    EXCESSIVE=0,
    TOTAL=1,
}

export enum scaleApplicationsName {
    EXCESSIVE="EXCESSIVE",
    TOTAL="TOTAL",
}

export enum customersTypes {
    ALL=0,
    NEW=1,
    EXISTING=2,
}

export enum customersTypesName {
    ALL="ALL",
    NEW="NEW",
    EXISTING="EXISTING",
}

export enum combinationsTypes {
    ELECTRICITY=0,
    GAS=1,
    ALL=2,
    NONE=3,
}

export enum combinationsTypesName {
    ELECTRICITY="ELECTRICITY",
    GAS="GAS",
    ALL="ALL",
    NONE="NONE",
}

export enum discountsTypes {
    CONSISTENCY_PROPORTIONAL=0,
    CONSISTENCY_FIXED=1,
    CHARGES_PROPORTIONAL=2,
    CHARGES_FIXED=3,
    CHARGES_PROPORTIONAL_EXTRA=4,
    CHARGES_FIXED_EXTRA=5,
    ADDITIONAL=6,
}

export enum discountsTypesName {
    CONSISTENCY_PROPORTIONAL="CONSISTENCY_PROPORTIONAL",
    CONSISTENCY_FIXED="CONSISTENCY_FIXED",
    CHARGES_PROPORTIONAL="CHARGES_PROPORTIONAL",
    CHARGES_FIXED="CHARGES_FIXED",
    CHARGES_PROPORTIONAL_EXTRA="CHARGES_PROPORTIONAL_EXTRA",
    CHARGES_FIXED_EXTRA="CHARGES_FIXED_EXTRA",
    ADDITIONAL="ADDITIONAL",
}

export enum chargeTypes {
    DAY=0,
    NIGHT=1,
}

export enum chargeTypesName {
    DAY="DAY",
    NIGHT="NIGHT",
}
