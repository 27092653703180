import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";

if ("serviceWorker" in navigator) {
    window.addEventListener("load", async function () {
        await navigator.serviceWorker.register("/service-worker.js");
    });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
                colorScheme: "light",
                colors: {
                    orange: ["hsl(47, 100%, 66%)", "hsl(45, 96%, 67%)", "hsl(42, 95%, 63%)", "hsl(39, 93%, 79%)", "hsl(38, 96%, 54%)", "hsl(35, 91%, 61%)", "hsl(33, 89%, 46%)", "hsl(30, 90%, 41%)", "hsl(28, 91%, 35%)", "hsl(25, 80%, 45%)"],
                    gray: ["hsl(168, 3%, 54%)", "hsl(168, 3%, 45%)", "hsl(168, 3%, 40%)", "hsl(168, 3%, 35%)", "hsl(168, 3%, 33%)", "hsl(168, 3%, 30%)", "hsl(168, 10%, 29%)", "hsl(168, 20%, 25%)", "hsl(168, 25%, 23%)", "hsl(168, 30%, 20%)"],
                },
                primaryColor: "orange",
                fontFamily: "Lato",
                components: {
                    SegmentedControl: {
                        styles: (theme) => ({
                            SegmentedControl: {
                                backgroundColor: theme.colorScheme === "light" ? theme.colors.orange[2] : theme.colors.orange[6],
                            },
                        }),
                    },
                },
            }}
        >
            <App />
        </MantineProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
