import { Center, Button, createStyles } from "@mantine/core";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const useStyles = createStyles((theme) => ({
    center: {
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        alignItems: "center",

        [theme.fn.smallerThan("sm")]: {
            justifyContent: "flex-start",
            flexDirection: "column",
        },
    },
    button: {
        width: 450,
        height: 200,
        borderRadius: 10,
        fontSize: 35,
        "&": {
            transition: "ease-in-out 1s",
        },
        [theme.fn.largerThan("sm")]: {
            "&:first-child:hover": {
                transform: "rotate(-2deg) translateX(-5%)",
            },
            "&:last-child:hover": {
                transform: "rotate(2deg) translateX(5%)",
            },
            "&:not(:first-child):not(:last-child):hover": {
                transform: "translateY(-5%)",
            },
        },
        [theme.fn.smallerThan("sm")]: {
            "&": {
                height: 180,
                width: "90%",
            },
            "&:hover": {
                transform: "translateY(-5%)",
            },
        },
    },
}));

interface Props {
    choices: { title: string; path: string; disabled?: boolean; icon?: ReactElement }[];
}

export function Click2Go({ choices }: Props) {
    const { classes } = useStyles();
    const navigate = useNavigate();
    return (
        <Center className={classes.center}>
            {choices.map((choice) => (
                <Button
                    key={choice.title}
                    className={classes.button}
                    title={choice.title}
                    color="hsl(33, 89%, 46%)"
                    onClick={() => {
                        if (choice.disabled) {
                            toast("Σύντομα διαθέσιμο!", {
                                position: "top-center",
                                autoClose: 1000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                type: toast.TYPE.INFO,
                            });
                        } else {
                            navigate(choice.path);
                        }
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
                        {choice.icon && <span>{choice.icon}</span>}
                        <div>{choice.title}</div>
                    </div>
                </Button>
            ))}
        </Center>
    );
}
